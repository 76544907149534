(function (angular)
{

    'use strict';

    /**
     * @ngdoc object
     * @name ntb-fe.value:userData
     * @description
     * User data model Value Object.
     *
     * Holds ALL user data AND is used as MODEL.
     *
     * @example
     * *userData* is injectable as Value Object
     * <pre>
     *     function myService(userData) {
     *
     *     }
     * </pre>
     */
    var userData = {
        isActive: false,
        registration: {
            uid: 'registration',
            msn: void 0,
            userChoices: {
                accountType: void 0,
                jointConsent: false
            },
            primary: emptyRegistrationApplicant (),
            secondary: emptyRegistrationApplicant (),
            interactions: {
                primary: {
                    previousName: void 0,
                    residence: void 0
                },
                secondary: {
                    previousName: void 0,
                    residence: void 0
                }
            }
        },
        contactDetails: {
            uid: 'contactDetails',
            primary: {
                residentialStatus: void 0,
                currentAddress: {
                    address: emptyAddress (),
                    yearsAtAddress: void 0,
                    monthsAtAddress: void 0
                },
                previousAddress: {
                    address: emptyAddress ()
                },
                mobileNumber: void 0,
                phoneNumber: void 0,
                marketingOptions: emptyMarketingOptions ()
            },
            secondary: {
                currentAddress: {
                    address: emptyAddress (),
                    yearsAtAddress: void 0,
                    monthsAtAddress: void 0
                },
                previousAddress: {
                    address: emptyAddress ()
                },
                mobileNumber: void 0,
                phoneNumber: void 0,
                marketingOptions: emptyMarketingOptions ()
            },
            interactions: {
                primary: {
                    currentAddress: contactDetailsInteractions (),
                    previousAddress: contactDetailsInteractions ()
                },
                secondary: {
                    currentAddress: contactDetailsInteractions (),
                    previousAddress: contactDetailsInteractions ()
                },
                sameAddressAsPrimary: void 0
            }
        },
        personalDetails: {
            uid: 'personalDetails',
            primary: emptyPersonalDetailsApplicant (),
            secondary: emptyPersonalDetailsApplicant (),
            interactions: {
                primary: emptyPersonalInteractionsApplicant (),
                secondary: emptyPersonalInteractionsApplicant ()
            }
        },
        employment: {
            uid: 'employment',
            primary: {
                status: void 0,
                jobTitle: void 0,
                timeAtEmployer: {
                    months: void 0,
                    years: void 0
                },
                seBusinessNature: void 0,
                employersName: void 0
            },
            secondary: {
                status: void 0
            },
            interactions: {
                primary: {
                    timeAtEmployer: {
                        month: void 0,
                        year: void 0
                    }
                }
            }
        },
        income: {
            uid: 'income',
            overdraft: void 0,
            overdraftPreviousValue: void 0,
            primary: {
                incomeBeforeTax: void 0,
                incomeAfterTax: void 0,
                retirementAge: void 0
            },
            secondary: {
                incomeBeforeTax: void 0,
                incomeAfterTax: void 0,
                retirementAge: void 0
            },
            interactions: {
                primary: emptyIncomeInteractions(),
                secondary: emptyIncomeInteractions()
            }
        },
        overdraft: {
            uid: 'overdraft',
            preContract: false,
            overdraftAmount: 100,
            primary: {

            },
            secondary: {

            },
            interactions: {
                primary: emptyOverdraftInteractions(),
                secondary: emptyOverdraftInteractions()
            }
        },
        bankDetails: {
            uid: 'bankDetails',
            accountNumber: void 0,
            rollNumber: void 0,
            sortcode: void 0,
            timeAccountHeld: {
                months: void 0,
                years: void 0
            },
            interactions: {
                existingCurrentAccount: void 0,
                accountOpened: {
                    month: void 0,
                    year: void 0
                }
            }
        },
        switching: {
            uid: 'switching',
            switching: void 0,
            fullSwitch: void 0,
            transferDate: void 0,
            partialAllPayments: void 0,
            partialSomePayments: void 0,
            debitCard: {
                primary: {
                    pan: '',
                    expiry: ''
                },
                secondary: {
                    pan: '',
                    expiry: ''
                }
            },
            accountToSwitch: {
                jointAccount: void 0,
                primaryApplicantAccount: void 0,
                secondaryApplicantAccount: void 0,
                accountNumber: void 0,
                sortcode: void 0
            },
            interactions: {
                switch: void 0,
                confirmAccount: void 0,
                acceptPartialOption: void 0,
                acceptSwitchDate: void 0,
                partialSwitchTransfer: void 0,
                agreement: void 0,
                primary: {
                    existingDebitCard: void 0
                },
                secondary: {
                    existingDebitCard: void 0
                }
            }
        },
        memorableWord: {
            uid: 'memorableWord',
            fscs: false,
            acceptTerms: false,
            primary: {
                word: void 0
            },
            secondary: {
                word: void 0
            }
        }
    };

    /**
     * @private
     * @returns {Object}
     */
    function emptyIncomeInteractions() {
        return {
            incomeBeforeTax: void 0,
            incomeBeforeTaxInterval: 'Yearly',
            incomeAfterTax: void 0,
            incomeAfterTaxInterval: 'Monthly',
            monthlyIncome: 0
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function emptyOverdraftInteractions() {
        return {
            mortgageOrRent: void 0,
            mortgageOrRentInterval: 'Monthly',
            councilTax: void 0,
            councilTaxInterval: 'Monthly',
            utilityBills: void 0,
            utilityBillsInterval: 'Monthly',
            tvAndBroadband: void 0,
            tvAndBroadbandInterval: 'Monthly',
            mobilePhone: void 0,
            mobilePhoneInterval: 'Monthly',
            livingCosts: void 0,
            livingCostsInterval: 'Monthly'
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function emptyPersonalDetailsApplicant ()
    {
        return {
            gender: void 0,
            maritalStatus: void 0,
            dependents: void 0,
            nationality: void 0,
            taxDetails: {
                taxResOutsideUKOrUSCit: void 0,
                usCitizen: void 0,
                usTaxResident: void 0,
                islandsTaxResident: void 0,
                usTaxNumber: '',
                niNumber: '',
                islandsCountry: '',
                otherCountry: void 0,
                additionalCountry: ''
            }
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function emptyPersonalInteractionsApplicant ()
    {
        return {
            dependents: void 0,
            otherCountry: void 0,
            additionalCountry: void 0
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function emptyRegistrationApplicant ()
    {
        return {
            title: void 0,
            firstName: void 0,
            middleName: void 0,
            surname: void 0,
            emailAddress: void 0,
            dateOfBirth: {
                day: void 0,
                month: void 0,
                year: void 0
            },
            previousNames: []
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function emptyMarketingOptions ()
    {
        return {
            email: void 0,
            post: void 0,
            sms: void 0,
            telephone: void 0,
            thirdParty: void 0
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function emptyAddress ()
    {
        return {
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            postcode: ''
        };
    }

    /**
     * @private
     * @returns {Object}
     */
    function contactDetailsInteractions ()
    {
        return {
            postcode: void 0,
            manualEntry: false,
            addressStartYear: void 0,
            addressStartMonth: void 0
        };
    }

    angular
        .module ('ntb-fe')
        .value ('userData', userData);

}) (window.angular);
