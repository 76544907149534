(function(angular) {

    'use strict';

    /**
     * @ngInject
     */
    function stickyHeader($window) {

        function link(scope, el, attrs, ngModelCtrl) {

            scope.checkScroll = function(pageYOffset) {
                if (pageYOffset >= 90) {
                    ngModelCtrl.$setViewValue(true);
                } else {
                    ngModelCtrl.$setViewValue(false);
                }
            };

            angular.element($window).bind('scroll', function () {
                scope.checkScroll(this.pageYOffset);
            });
        }

        return {
            restrict: 'A',
            require: '?ngModel',
            scope: '=',
            link   : link
        };
    }

    angular.module('ntb-fe')
        .directive('stickyHeader', stickyHeader);

})(window.angular);