(function(angular) {

    'use strict';

    /**
     *
     * @returns {Function}
     * @ngInject
     */
    function analyticsDetailFormFieldFilter() {
        return function (data) {
            var result = [];
            for (var i = 0, len = data.length; i < len; i++) {
                result.push((i + 1).toString() + ':' + data[i]);
            }
            return result.join(',');
        };
    }

    angular
        .module('ntb-fe')
        .filter('analyticsDetailFormFieldFilter', analyticsDetailFormFieldFilter);

})(window.angular);