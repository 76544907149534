(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:resumeService
     * @requires ntb-fe.service:timeoutService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:ResumeController
     * @description
     * Resume application controller
     *
     * @example
     * *ResumeController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'ResumeController as resumeCtrl'
     * </pre>
     *
     * @ngInject
     */
    function ResumeController($log, $rootScope, $state, $localStorage, resumeService, timeoutService, dataModelService, analyticsService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ResumeController
         * @name ntb-fe.controller:ResumeController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ResumeController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="resumeCtrl.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            isValidating    : false,
            isLoading       : false,
            hasFormError    : false,
            serverError     : false,
            invalidEmail    : false,
            emailSendError  : false,
            validationError : false,
            dateOfBirthError: false,
            accountLocked   : false,
            uuid            : !!$state.params.uuid,
            resumeRequested : false
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ResumeController
         * @name vm.notifications
         * @description
         * User data object - passed to DOM and B/E
         *
         * @type {Object}
         */
        vm.userData = {
            emailAddress: $localStorage.get('emailAddress') || '',
            uuid        : $state.params.uuid,
            dateOfBirth : void 0
        };

        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @private
         */
        function requestResumeEmail() {

            if (vm.notifications.isLoading) {
                return;
            }

            analyticsService.trackEvent('NTB - Email Submit', 'NTB - Email Submit');

            // Store the user's email address in session storage
            $localStorage.put('emailAddress', vm.userData.emailAddress);
            analyticsService.finishFormTracking();

            vm.notifications.serverError = false;
            vm.notifications.isLoading = true;

            resumeService.request(vm.userData.emailAddress)
                .then(function() {
                    vm.notifications.invalidEmail = false;
                    vm.notifications.isLoading = false;
                    vm.notifications.emailSendError = false;
                    vm.notifications.resumeRequested = true;
                }, function(error) {

                    if (error.status === 400) {
                        vm.notifications.invalidEmail = true;
                        vm.notifications.emailSendError = false;
                        analyticsService.trackServerError('requestResumeEmail: INVALID EMAIL');
                    } else {
                        vm.notifications.invalidEmail = false;
                        vm.notifications.emailSendError = true;
                        analyticsService.trackServerError('requestResumeEmail: ' + error.status);
                    }

                    vm.notifications.isLoading = false;
                });
        }

        /**
         * @private
         */
        function authenticateDateOfBirth() {

            if (vm.notifications.isLoading) {
                return;
            }

            vm.notifications.serverError = false;
            vm.notifications.isLoading = true;

            analyticsService.finishFormTracking();

            resumeService.authenticate(vm.userData)
                .then(function() {

                    vm.notifications.dateOfBirthError = false;
                    vm.notifications.accountLocked = false;
                    
                    $state.go(dataModelService.resumePoint);

                }, function(error) {
                    vm.notifications.isLoading = false;

                    if (error.status === 401) {
                        vm.notifications.dateOfBirthError = true;
                        analyticsService.trackServerError('authenticateDateOfBirth: DOB INCORRECT');
                        return;
                    } else if (error.status === 400) {
                        vm.notifications.accountLocked = true;
                        analyticsService.trackServerError('authenticateDateOfBirth: ACCOUNT LOCKED');
                        return;
                    }

                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('authenticateDateOfBirth: ' + error.status);
                });
        }

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:ResumeController
         * @name vm.resumeSubmit
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Boolean} valid Submitted form *$valid* flag
         *
         * @example
         * <pre>
         *     <form name="resumeForm" data-ng-submit="resumeCtrl.resumeSubmit(contactForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.resumeSubmit = function(isValid) {

            // Update the form error notification
            vm.notifications.hasFormError = !isValid;

            analyticsService.finishFormTracking();

            // Resume
            if (!isValid) {
                $rootScope.$broadcast('hasFormError');
                return;
            }

            if (!vm.notifications.uuid) {
                requestResumeEmail();
            } else {
                authenticateDateOfBirth();
            }
        };

        // IIFE Init
        (function() {

            analyticsService.startFormTracking();

            // Clear the data model now
            dataModelService.clearDataModel();

            if (!vm.notifications.uuid) {
                timeoutService.endSession().then(function() {
                    $log.debug('END SESSION');
                });
                return;
            }

            vm.notifications.isValidating = true;

            resumeService.check($state.params.uuid)
                .then(function(response) {
                    vm.notifications.isValidating = false;
                    vm.notifications.validationError = false;

                    $log.debug(response);
                }, function(error) {
                    vm.notifications.isValidating = false;
                    vm.notifications.validationError = true;
                    vm.notifications.uuid = false;

                    analyticsService.trackServerError('check UUID: ' + error.status);
                    $log.error(error);
                });
        })();
    }

    angular
        .module('ntb-fe')
        .controller('ResumeController', ResumeController);

})(window.angular);