(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:unsubscribeService
     * @description
     *
     * Unsubscribe service
     *
     * @requires ntb-fe.factory:unsubscribe
     *
     * @example
     *
     * <pre>
     *     function SomeController(unsubscribeService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function unsubscribeService($q, unsubscribe) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:unsubscribeService
         * @name ntb-fe.service:unsubscribeService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:unsubscribeService
         * @name service.callUnsubscribe
         * @description
         * Unsubscribe from emails via the unsubscribe factory and handle the response
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.callUnsubscribe = function(uuid) {
            return $q(function(resolve, reject) {
                unsubscribe.save({ uuid: uuid }, function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('unsubscribeService', unsubscribeService);

})(window.angular);