(function(angular) {

    'use strict';

    /**
     * @ngdoc directive
     * @name ntb-fe.directive:blurCurrency
     * @description
     * Reformats inputted String 1234 to £1,234 etc.
     *
     * @restrict A
     *
     * @ngInject
     *
     * @returns {Object} Directive
     */
    function blurCurrency($filter) {

        function link(scope, el, attrs, ngModelCtrl) {

            function formatter(value) {

                if (angular.isUndefined(value)) {
                    return void 0;
                }

                value = parseFloat(value.toString().replace(/[^0-9._-]/g, ''));

                if (attrs.max) {
                    value = parseFloat(value.toString().substr(0, parseInt(attrs.max, 10)));
                }

                ngModelCtrl.$setValidity('negative', parseInt(value, 10) >= 0);

                var formattedValue = $filter('currency')(value, '£', 0);
                el.val(formattedValue);

                ngModelCtrl.$setViewValue(value);
                return formattedValue;
            }

            attrs.$set('ngTrim', 'false');
            ngModelCtrl.$formatters.push(formatter);

            el.on('keydown', function() {
                ngModelCtrl.$setValidity('negative', true);
            });

            el.on('blur keyup', function(event) {
                // Allow arrow key movement
                if (event.keyCode !== 37 && event.keyCode !== 39) {
                    formatter(el.val());
                }
            });
        }

        return {
            restrict: 'A',
            require: '?ngModel',
            link   : link
        };
    }

    angular.module('ntb-fe')
        .directive('blurCurrency', blurCurrency);

})(window.angular);