(function (angular) {

    'use strict';

    function switchingDataFilter() {
        return function(userData) {

            var copy = angular.copy(userData);

            function parseExpiry(expiry) {
                var inputExpiryDate = expiry.replace(/[\/-]/g, '').split('');

                if (inputExpiryDate[2]) {
                    inputExpiryDate.splice(2, 0, '/');
                }

                return inputExpiryDate.join('');
            }

            if (copy && copy.debitCard) {
                if (copy.debitCard.primary && copy.debitCard.primary.expiry) {
                    copy.debitCard.primary.expiry = parseExpiry(copy.debitCard.primary.expiry);
                }
                if (copy.debitCard.secondary && copy.debitCard.secondary.expiry) {
                    copy.debitCard.secondary.expiry = parseExpiry(copy.debitCard.secondary.expiry);
                }
            }

            return copy;
        };
    }

    angular
        .module('ntb-fe')
        .filter('switchingDataFilter', switchingDataFilter);

})(window.angular);