(function(angular) {

    'use strict';

    /**
     * @ngdoc directive
     * @name ntb-fe.directive:dateField
     * @description
     * Reformats inputted date to DD/MM/YYYY as a String
     *
     * @restrict E
     *
     * @ngInject
     *
     * @returns {Object} Directive
     */
    function sortcodeField($filter) {

        function link(scope, element, attrs, ngModel) {

            function update(scope, event) {

                // Numerical KeyCodes only
                if (event && event.keyCode && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
                    var parsedValue = $filter('bankDetailsFilter')({ sortcode: element.val() }).sortcode;
                    element.val(parsedValue);
                    ngModel.$setViewValue(parsedValue);
                }
            }

            element.on('blur change keyup', function(event) {
                scope.$evalAsync(update, event);
            });
            update(scope, { keyCode: 49 });
        }

        return {
            restrict   : 'A',
            require    : '^ngModel',
            link       : link
        };
    }

    angular
        .module('ntb-fe')
        .directive('sortcodeField', sortcodeField);

})(window.angular);
