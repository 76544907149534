(function(angular) {

    'use strict';

    /**
     * @ngdoc object
     * @name ntb-fe.constant:appInfo
     * @description
     * Application information Constant.
     *
     * Holds application version and build numbers.
     *
     * @example
     * *appInfo* is injectable as value
     * <pre>
     *     function myService(appInfo) {
     *         console.info('Version:', appInfo.VERSION, 'Build:', appInfo.BUILD);
     *     }
     * </pre>
     */
    var appInfo = {
        VERSION: '@@version',
        BUILD  : '@@build'
    };

    angular
        .module('ntb-fe')
        .constant('appInfo', appInfo);

})(window.angular);