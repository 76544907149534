(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     * @name ntb-fe.filter:addressResultsFilter
     * @description
     * Address lookup results filter
     *
     * @return {Function} Filter method
     *
     * @example
     * <pre>
     *     $filter('addressResultsFilter')(response);
     * </pre>
     *
     * @ngInject
     */
    function addressResultsFilter() {

        return function(response) {

            var street = response.streetName,
                district = response.district,
                town = response.town,
                additionalInformation = [];

            if (street && street.length) {
                additionalInformation.push(street);
            }
            if (district && district.length) {
                additionalInformation.push(district);
            }
            if (town && town.length) {
                additionalInformation.push(town);
            }

            response.additionalInfo = ' ' + additionalInformation.join(', ');
            return response;
        };
    }

    angular
        .module('ntb-fe')
        .filter('addressResultsFilter', addressResultsFilter);

})(window.angular);