(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:personalService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:PersonalDetailsController
     * @description
     * Personal details controller
     *
     * @example
     * *PersonalDetailsController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'PersonalDetailsController as contact'
     * </pre>
     *
     * @ngInject
     */
    function PersonalDetailsController($log, $state, $rootScope, personalService, dataModelService, analyticsService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:PersonalDetailsController
         * @name ntb-fe.controller:PersonalDetailsController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:PersonalDetailsController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError: false,
            serverError: false,
            isSubmitting: false,
            isJointApplication: dataModelService.isJointApplication(),
            showGender: {
                primary: showGender('primary'),
                secondary: dataModelService.isJointApplication() ? showGender('secondary') : false
            },
            applicantName: {
                primary: dataModelService.getFirstName('primary'),
                secondary: dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : ''
            }
        };

        /**
         * @private
         *
         * @param applicant
         * @returns {boolean}
         */
        function showGender(applicant) {
            var userDetails = dataModelService.getUserDetails(applicant);
            return userDetails.title === 'Dr' || userDetails.title === 'Prof' || userDetails.title === 'Mx';
        }

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:PersonalDetailsController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('personalDetails');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('personalDetails');

        /**
         *
         * @param item
         */
        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         *
         * @param applicant
         */
        vm.resetDependents = function (applicant) {
            vm.userData[applicant].dependents = void 0;
            $rootScope.$applyAsync(function () {
                vm.userData[applicant].dependents = void 0;
            });
        };

        /**
         *
         * @param applicant
         * @param value
         */
        vm.resetUsCitizen = function (applicant, value) {
            /* Are you a US citizen? */
            if (vm.userData[applicant] && vm.userData[applicant].taxDetails) {
                vm.userData[applicant].taxDetails.usCitizen = angular.isDefined(value) ? value : void 0;
            }
            vm.resetUsTaxResidency(applicant);
        };

        /**
         *
         * @param applicant
         */
        vm.resetUsTaxResidency = function (applicant, value) {
            /* Are you resident in the USA for tax purposes? */
            if (vm.userData[applicant] && vm.userData[applicant].taxDetails) {
                vm.userData[applicant].taxDetails.usTaxNumber = void 0;
                vm.userData[applicant].taxDetails.usTaxResident = angular.isDefined(value) ? value : void 0;
            }
        };

        /**
         *
         * @param applicant
         */
        vm.resetOtherCountry = function (applicant, value) {
            /* Are you a resident for tax purposes in any other country? */
            if (vm.userData[applicant] && vm.userData[applicant].taxDetails) {
                vm.userData[applicant].taxDetails.otherCountry = void 0;
            }
            if (vm.userData.interactions[applicant]) {
                vm.userData.interactions[applicant].otherCountry = angular.isDefined(value) ? value : void 0;
            }
        };

        /**
         *
         * @param applicant
         */
        vm.resetAdditionalCountry = function (applicant, value) {
            /* Are you a resident for tax purposes in any additional country? */
            if (vm.userData[applicant] && vm.userData[applicant].taxDetails) {
                vm.userData[applicant].taxDetails.additionalCountry = void 0;
            }
            if (vm.userData.interactions[applicant]) {
                vm.userData.interactions[applicant].additionalCountry = angular.isDefined(value) ? value : void 0;
            }
        };

        /*
         *
         * @param applicant
         * * @param {Boolean} keep Keep country selection
         */
        vm.resetIslands = function (applicant, keep) {

            $log.debug('Reset islands:', applicant, keep, vm.userData);

            /* Are you a resident for tax purposes in Gibraltar, Guernsey Jersey or the Isle of Man? */
            vm.resetNiNumber(applicant);
            if (!keep) {
                vm.resetIslandsCountry(applicant);
            }
        };

        /**
         *
         * @param applicant
         */
        vm.resetNiNumber = function (applicant) {
            if (vm.userData[applicant] && vm.userData[applicant].taxDetails) {
                vm.userData[applicant].taxDetails.niNumber = '';
            }
        };

        /**
         *
         * @param {String} applicant Primary|Secondary
         */
        vm.resetIslandsCountry = function (applicant) {

            if (vm.userData[applicant] && vm.userData[applicant].taxDetails) {
                vm.userData[applicant].taxDetails.islandsCountry = void 0;
                vm.resetOtherCountry(applicant);
            }
        };

        /**
         *
         * @param applicant
         * @param option
         */
        vm.resetFatca = function (applicant, option) {
            vm.resetUsCitizen(applicant);
            vm.resetOtherCountry(applicant);
            vm.resetAdditionalCountry(applicant);
            vm.resetIslands(applicant);

            vm.userData[applicant].taxDetails.taxResOutsideUKOrUSCit = void 0;
            vm.userData[applicant].taxDetails.islandsTaxResident = void 0;

            if (angular.isDefined(option)) {
                vm.userData[applicant].taxDetails.taxResOutsideUKOrUSCit = option;
            }
        };

        /**
         * @private
         *
         * @param applicant
         */
        function updateGender(applicant) {
            var userDetails = dataModelService.getUserDetails(applicant);

            switch (userDetails.title) {
                case 'Mr':
                    vm.userData[applicant].gender = 'M';
                    break;
                case 'Miss':
                case 'Mrs':
                case 'Ms':
                    vm.userData[applicant].gender = 'F';
                    break;
            }
        }

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:PersonalDetailsController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="contactForm" data-ng-submit="contact.submitUserData(contactForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function (form) {

            vm.notifications.hasFormError = !form.$valid;

            // Personal Details
            if (vm.notifications.hasFormError) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            updateGender('primary');

            if (vm.notifications.isJointApplication) {
                updateGender('secondary');
            }

            // Personal details - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;

            dataModelService.updateModel('personalDetails', vm.userData);
            analyticsService.addRoute('employment');
            analyticsService.finishFormTracking();

            personalService.submitPersonalDetails(vm.userData)
                .then(function () {
                    $state.go('employment');
                }, function (error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitPersonalDetails: ' + error.status);
                });
        };

        // IIFE init
        (function() {
            analyticsService.startFormTracking();
        })();
    }

    angular
        .module('ntb-fe')
        .controller('PersonalDetailsController', PersonalDetailsController);

})(window.angular);
