(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:session
     * @description
     * Session factory
     *
     * @requires ntb-fe.constant:defaults
     *
     * @ngInject
     */
    function session($resource, defaults) {

        return $resource('unused', null, {
            start: {
                method: 'GET',
                cache: false,
                url: defaults.API_BASE_PATH + 'init'
            },
            keepAlive: {
                method: 'GET',
                cache: false,
                url: defaults.API_BASE_PATH + 'secure/session/keepalive?:time'
            },
            logout: {
                method: 'GET',
                cache: false,
                url: defaults.API_BASE_PATH + 'secure/session/logout'
            }
        });
    }

    angular
        .module('ntb-fe')
        .factory('session', session);

})(window.angular);