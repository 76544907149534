(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:employmentDataFilter
     * @description
     * Filters the employment userData
     *
     * @example
     * <pre>
     *     $filter('dateOfBirth')(date);
     * </pre>
     *
     * @ngInject
     */
    function employmentDataFilter() {
        
        return function(tmpData, cleanModel) {

            for (var i = 0, applicants = ['primary', 'secondary'], tmpStatus, tmpRetirement, len = applicants.length; i < len; i ++) {

                tmpRetirement = void 0;

                if (tmpData[applicants[i]] && tmpData[applicants[i]].status && (tmpData[applicants[i]].status === 'HousePerson' || tmpData[applicants[i]].status === 'Student' || tmpData[applicants[i]].status === 'Unemployed' || tmpData[applicants[i]].status === 'Retired')) {
                    tmpStatus = tmpData[applicants[i]].status;

                    tmpData[applicants[i]] = angular.extend({}, cleanModel[applicants[i]]);
                    tmpData[applicants[i]].status = tmpStatus;
                }

                if (tmpData[applicants[i]].retirementAge && tmpData[applicants[i]].status !== 'Retired') {
                    tmpRetirement = tmpData[applicants[i]].retirementAge;
                }

                tmpData[applicants[i]].retirementAge = tmpRetirement;

                if (tmpData[applicants[i]] && tmpData[applicants[i]].status && tmpData[applicants[i]].status !== 'SelfEmployed') {
                    tmpData[applicants[i]].seBusinessNature = void 0;
                }
            }

            return angular.extend({}, tmpData);
        };
    }

    angular
        .module('ntb-fe')
        .filter('employmentDataFilter', employmentDataFilter);

})(window.angular);