(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:dataModelService
     * @description
     * Data model service
     *
     * @requires ntb-fe.service:momentService
     * @requires ntb-fe.value:userData
     *
     * @example
     *
     * <pre>
     *     function SomeController(dataModelService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function dataModelService($log, $filter, $sessionStorage, momentService, userData) {

        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         *
         * @type {string}
         */
        service.resumePoint = 'start';

        /**
         *
         * @returns {*}
         */
        function getStoredData() {
            return $sessionStorage.get('dataStore') || {};
        }

        /**
         *
         * @returns {boolean}
         */
        service.hasStoredData = function() {
            return Object.keys(getStoredData()).length !== 0;
        };

        /**
         *
         * @returns {Object} Data model Object
         */
        service.getFullModelData = function() {
            return angular.copy(service.hasStoredData() ? getStoredData() : userData);
        };

        /**
         *
         * @param {String} endpoint Endpoint
         * @returns {Object} Data model Object
         */
        service.getModelData = function(endpoint) {

            var returnObject,
                tmpData = angular.extend({}, userData[endpoint]);

            if (getStoredData()[endpoint] && getStoredData()[endpoint].uid) {
                returnObject = angular.extend({}, getStoredData()[endpoint]);
            } else {
                returnObject = angular.extend({}, userData[endpoint]);
            }

            if (service.isJointApplication()) {
                if (angular.isDefined(tmpData.secondary) && angular.isUndefined(returnObject.secondary)) {
                    returnObject.secondary = tmpData.secondary;
                }

                if (tmpData.interactions && angular.isDefined(tmpData.interactions.secondary) && returnObject.interactions && angular.isUndefined(returnObject.interactions.secondary)) {
                    returnObject.interactions.secondary = tmpData.interactions.secondary;
                }
            }

            return returnObject;
        };

        /**
         *
         * @param endpoint
         */
        service.getBlankModelData = function(endpoint) {
            return angular.copy(userData[endpoint]);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name checkResumeData
         * @description
         * Check the resume data from B/E and update the model
         *
         * @param {Object} resumeData Resume data from B/E
         */
        service.checkResumeData = function(resumeData) {
            service.resumePoint = $filter('resumePoint')(resumeData);
            updateSession($filter('resumeData')(resumeData));
            $log.debug('Checking resume data:', resumeData, 'Resume point:', service.resumePoint);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name clearDataModel
         * @description
         * Clear the data model
         */
        service.clearDataModel = function() {
            $sessionStorage.remove('dataStore');
            $sessionStorage.remove('preambleChoices');
            $sessionStorage.empty();
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name getAccountType
         * @description
         * Get the account type [sole|joint]
         *
         * @returns {String} Account type selected [sole|joint]
         */
        service.getAccountType = function() {
            try {
                return getStoredData().registration.userChoices.accountType;
            }
            catch(e) {
                $log.warn('dataModelService: ACCOUNT TYPE NOT SET');
                return 'ERROR';
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name hasRequestedOverdraft
         * @description
         * Get a flag indicating whether the prospect has requested an overdraft
         *
         * @returns {Boolean} true if the prospect has requested an overdraft
         */
        service.hasRequestedOverdraft = function() {
            try {
                return getStoredData().income.overdraft;
            }
            catch(e) {
                $log.warn('dataModelService: INCOME');
                return 'ERROR';
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name isJointApplication
         * @description
         * Is the application a joint application
         *
         * @returns {Boolean} Account type is 'joint'
         */
        service.isJointApplication = function() {
            return service.getAccountType() === 'joint';
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name getFirstName
         * @description
         * Get the applicant firstName
         *
         * @param {String} applicant Applicant [primary|secondary]
         * @returns {String} Applicant firstName
         */
        service.getFirstName = function(applicant) {
            try {
                return getStoredData().registration[applicant].firstName;
            }
            catch(e) {
                $log.warn('dataModelService: ' + applicant.toUpperCase() + ' APPLICANT FIRST NAME NOT SET');
                return 'ERROR';
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name getDateOfBirth
         * @description
         * Get the applicant Date of birth
         *
         * @param {String} applicant Applicant [primary|secondary]
         * @returns {String} Applicant date of birth
         */
        service.getDateOfBirth = function(applicant) {
            try {
                return $filter('dateOfBirth')(getStoredData().registration[applicant].dateOfBirth);
            }
            catch(e) {
                $log.warn('dataModelService: APPLICANT DOB NOT SET');
                return 'ERROR';
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name getApplicantAge
         * @description
         * Get the applicant age
         *
         * @param {String} applicant Applicant [primary|secondary]
         * @returns {Number} Applicant age
         */
        service.getApplicantAge = function(applicant) {
            return momentService.calculateAge(service.getDateOfBirth(applicant));
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:dataModelService
         * @name getUserDetails
         * @description
         * Get the applicant name and address
         *
         * @param {String} applicant Applicant [primary|secondary]
         * @returns {Object} Applicant user details
         */
        service.getUserDetails = function(applicant) {
            var returnObj = {},
                storedData = getStoredData();

            try {
                returnObj.title = storedData.registration[applicant].title;
                returnObj.firstName = storedData.registration[applicant].firstName;
                returnObj.surname = storedData.registration[applicant].surname;
                returnObj.address = storedData.contactDetails[applicant].currentAddress.address;
                returnObj.age = service.getApplicantAge(applicant);
                returnObj.employmentStatus = storedData.employment[applicant].status;
                returnObj.monthlyIncome = storedData.income.interactions[applicant].monthlyIncome;

                if (applicant === 'primary') {
                    returnObj.accountNumber = storedData.bankDetails.accountNumber;
                    returnObj.sortcode = storedData.bankDetails.sortcode;
                }

            } catch(e) {
                $log.debug('Unable to retrieve all user data for', applicant, 'applicant.');
            }

            $log.debug('Retrieve all user data for', applicant, 'applicant.', returnObj);

            return returnObj;
        };

        /**
         *
         * @param endpoint
         * @param data
         */
        service.updateModel = function(endpoint, data) {

            var keys = Object.keys(data),
                storedData = getStoredData();

            if (angular.isUndefined(storedData[endpoint])) {
                storedData[endpoint] = data;
            } else {
                for (var i = 0, len = keys.length; i < len; i ++) {
                    storedData[endpoint][keys[i]] = data[keys[i]];
                }
            }

            $log.info('dataModelService: updateModel for ' + endpoint + ':', storedData);

            updateSession(storedData);
        };

        /**
         * @private
         */
        function updateSession(data) {
            $sessionStorage.put('dataStore', data);
            $log.debug('Updated user data in session:', $sessionStorage.get('dataStore'));
        }
    }

    angular
        .module('ntb-fe')
        .service('dataModelService', dataModelService);

})(window.angular);
