(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:employmentService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:EmploymentController
     * @description
     * Contact details controller
     *
     * @example
     * *EmploymentController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'EmploymentController as contact'
     * </pre>
     *
     * @ngInject
     */
    function EmploymentController($log, $state, $rootScope, momentService, employmentService, dataModelService, analyticsService, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:EmploymentController
         * @name ntb-fe.controller:EmploymentController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:EmploymentController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError      : false,
            serverError       : false,
            isSubmitting      : false,
            isJointApplication: dataModelService.isJointApplication(),
            hasDateError      : false,
            primary           : {
                firstName        : dataModelService.getFirstName('primary'),
                futureDate       : false,
                dateBeforeDob    : false,
                overMaxYears     : false
            },
            secondary         : {
                firstName        : dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : ''
            }
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:EmploymentController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('employment');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('employment');

        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * 
         */
        vm.checkTimeWithEmployer = function(applicant) {

            // $log.debug('checkTimeWithAccount:', vm.userData.interactions[applicant].timeAtEmployer);

            var startMonth = vm.userData.interactions[applicant].timeAtEmployer.month,
                startYear = vm.userData.interactions[applicant].timeAtEmployer.year,
                timeWithEmployer,
                ageAtEmployment;

            if (!startMonth || !startYear) {
                return;
            }

            ageAtEmployment = momentService.calculateAgeAtDate(dataModelService.getDateOfBirth(applicant), startMonth, startYear);

            vm.notifications.hasDateError = false;
            timeWithEmployer = momentService.calculateEmploymentTime(startMonth, startYear);

            vm.notifications[applicant].futureDate = vm.notifications.hasDateError = !timeWithEmployer;
            vm.notifications[applicant].dateBeforeDob = false;
            vm.notifications[applicant].overMaxYears = false;

            if (momentService.isMonthBeforeDob(dataModelService.getDateOfBirth(applicant), startMonth, startYear)) {
                vm.notifications.hasDateError = true;
                vm.notifications[applicant].dateBeforeDob = true;
            }

            if (!timeWithEmployer) {
                vm.notifications.hasDateError = true;
                return;
            }

            $log.debug('AGE AT START OF EMPLOYMENT:', ageAtEmployment);

            if (timeWithEmployer.years > defaults.MAX_EMPLOYMENT_YEARS || ageAtEmployment.years < defaults.MIN_EMPLOYMENT_AGE_YEARS) {
                vm.notifications.hasDateError = true;
                vm.notifications[applicant].overMaxYears = true;
            }

            vm.userData[applicant].timeAtEmployer = timeWithEmployer;
        };

        /**
         *
         * @param applicant
         * @param form
         */
        vm.changeStatus = function(applicant, form) {

            var tmpData = dataModelService.getBlankModelData('employment');
            tmpData[applicant].status = vm.userData[applicant].status;

            vm.userData.interactions[applicant] = angular.copy(tmpData.interactions[applicant]);
            vm.userData[applicant] = angular.copy(tmpData[applicant]);

            if (applicant === 'primary') {
                vm.userData.interactions[applicant].timeAtEmployer.month = void 0;
                vm.userData.interactions[applicant].timeAtEmployer.year = void 0;
                vm.userData[applicant].timeAtEmployer.months = void 0;
                vm.userData[applicant].timeAtEmployer.years = void 0;
            }

            // The model is not syncing correctly without triggering a digest
            $rootScope.$applyAsync(function() {
                if (form) {
                    form.$setPristine();
                    form.$setUntouched();
                }
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:EmploymentController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="employmentForm" data-ng-submit="employment.submitUserData(employmentForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {

            vm.notifications.hasFormError = !form.$valid;

            // Employment
            if (vm.notifications.hasFormError) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // Employment - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.hasDateError = false;
            vm.notifications.serverError = false;

            // Check dates
            vm.checkTimeWithEmployer('primary');

            if (vm.notifications.hasDateError) {
                vm.notifications.isSubmitting = false;
                return;
            }

            if (vm.notifications.hasDateError) {
                vm.notifications.isSubmitting = false;
                return;
            }

            dataModelService.updateModel('employment', vm.userData);
            analyticsService.addRoute('income');
            analyticsService.finishFormTracking();

            employmentService.submitEmploymentDetails(vm.userData)
                .then(function() {
                    $state.go('income');
                }, function(error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitEmploymentDetails: ' + error.status);
                });
        };

        // IIFE init
        (function() {
            analyticsService.startFormTracking();
        })();
    }

    angular
        .module('ntb-fe')
        .controller('EmploymentController', EmploymentController);

})(window.angular);