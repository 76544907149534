(function (angular, window) {

    'use strict';

    /**
     * @private
     *
     * @name config
     * @description
     * Main application config method
     *
     * @ngInject
     */
    function config($compileProvider, $logProvider, $stateProvider, $locationProvider, $urlRouterProvider, $httpProvider, $interpolateProvider) {

        // Disable debug classes | angular.reloadWithDebugInfo(); in console to enable
        $compileProvider.debugInfoEnabled(false);

        // Disable debug level log messages
        $logProvider.debugEnabled(false);

        // Intercept all $http response errors with httpInterceptor factory.
        $httpProvider.interceptors.push('httpInterceptor');

        // Include session cookies
        $httpProvider.defaults.withCredentials = true;

        // Set default timeout - NOTE: This is also set in the httpInterceptor.
        $httpProvider.defaults.timeout = 30000;

        // Reconfigure the AngularJS binding syntax to avoid conflicting with Handlebars.
        $interpolateProvider.startSymbol('{[{').endSymbol('}]}');

        // Re-route unavailable paths to home
        $urlRouterProvider.otherwise('/');

        // Configure states
        $stateProvider
            .state('start', {
                url: '/',
                controller: 'PreambleController as preamble',
                templateUrl: 'views/preamble'
            })
            .state('registration', {
                url: '/registration',
                controller: 'RegistrationController as registration',
                templateUrl: 'views/registration'
            })
            .state('contactDetails', {
                url: '/contact-details',
                params: {
                    secure: true
                },
                controller: 'ContactDetailsController as contactDetails',
                templateUrl: 'views/contactDetails'
            })
            .state('personalDetails', {
                url: '/personal-details',
                params: {
                    secure: true
                },
                controller: 'PersonalDetailsController as personal',
                templateUrl: 'views/personalDetails'
            })
            .state('employment', {
                url: '/employment',
                params: {
                    secure: true
                },
                controller: 'EmploymentController as employment',
                templateUrl: 'views/employment'
            })
            .state('income', {
                url: '/income',
                params: {
                    secure: true
                },
                controller: 'IncomeController as income',
                templateUrl: 'views/income'
            })
            .state('overdraft', {
                url: '/overdraft',
                params: {
                    secure: true
                },
                controller: 'OverdraftController as overdraft',
                templateUrl: 'views/overdraft'
            })
            .state('bankDetails', {
                url: '/bank-details',
                params: {
                    secure: true
                },
                controller: 'BankDetailsController as bankDetails',
                templateUrl: 'views/bankDetails'
            })
            .state('switching', {
                url: '/switching',
                params: {
                    secure: true
                },
                controller: 'SwitchingController as switching',
                templateUrl: 'views/switching'
            })
            .state('memorableWord', {
                url: '/memorable-word',
                params: {
                    secure: true
                },
                controller: 'MemorableWordController as memorableWord',
                templateUrl: 'views/memorableWord'
            })
            .state('resume', {
                url: '/resume?uuid',
                controller: 'ResumeController as resumeCtrl',
                templateUrl: 'views/resume?UUID'
            })
            .state('unsubscribe', {
                url: '/unsubscribe/:uuid',
                controller: 'UnsubscribeController as unsubscribe',
                templateUrl: 'views/unsubscribe'
            });

        // Hashbang mode
        $locationProvider.html5Mode(false);
        $locationProvider.hashPrefix('!');
    }

    /**
     * @private
     *
     * @name run
     * @description
     * Main application run method
     *
     * @ngInject
     */
    function run($log, $rootScope, $sessionStorage, timeoutService, appInfo) {

        $log.info('ntb-fe v' + appInfo.VERSION + ' (' + appInfo.BUILD + ')');
        $log.debug('AngularJS v' + angular.version.full, '(' + angular.version.codeName + ')');

        $sessionStorage.put('ntb-fe-info', appInfo);

        var stateChangeStartWatcher = $rootScope.$on('$stateChangeStart', function (event, toState) {

                if (toState.name === 'resume') {
                    timeoutService.cancelTimeout();
                } else if ((toState.params && toState.params.secure) || timeoutService.isInSession) {
                    $log.debug('State changed to ' + toState.name + ',', 'resetting timers.');
                    timeoutService.resetTimer();
                }
            }),
            stateChangeSuccesstWatcher = $rootScope.$on('$viewContentLoaded', function () {

                var focussed = false;

                angular.forEach(window['document'].getElementsByTagName('h1'), function(item) {
                    if (!focussed) {
                        focussed = true;
                        item.focus();
                    }
                });

            }),
            destroyWatcher = $rootScope.$on('$destroy', function () {
                stateChangeStartWatcher();
                stateChangeSuccesstWatcher();
                destroyWatcher();
            });
    }

    /**
     * @ngdoc interface
     *
     * @name ntb-fe
     * @description
     * New To Bank Front-end
     *
     * Front end application for the co-operative bank "New to Bank" project by {@link http://www.radicalcompany.com Radical Company}.
     */
    angular
        .module('ntb-fe', ['ngAnimate', 'ngAria', 'ngCookies', 'ngMessages', 'ngResource', 'ngTouch', 'ngSanitize', 'ui.router', 'ng.deviceDetector', 'swxLocalStorage', 'swxSessionStorage', 'TealiumHelper', 'TealiumHelper.directive'])
        .config(config)
        .run(run);

})(window.angular, window);