(function (angular) {

    'use strict';

    function bankDetailsFilter() {
        return function(userData) {
            var copy = angular.copy(userData);
            if (copy && copy.sortcode) {
                var inputValues = copy.sortcode.replace(/[\/-]/g, '').split('');

                if (inputValues[2] && inputValues[2] !== '-') {
                    inputValues.splice(2, 0, '-');
                }
                if (inputValues[5] && inputValues[5] !== '-') {
                    inputValues.splice(5, 0, '-');
                }
                copy.sortcode = inputValues.join('');
            }
            return copy;
        };
    }

    angular
        .module('ntb-fe')
        .filter('bankDetailsFilter', bankDetailsFilter);

})(window.angular);