(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:unsubscribe
     * @description
     * **Factory**
     *
     * Unsubscribe factory
     *
     * @requires ntb-fe.constant:defaults
     * @return {Object} ngResource instance
     *
     * @example
     *
     * <pre>
     *     unsubscribe.save({ uuid: 12345678 }, function(response) {
     *         ...
     *     }, function(error) {
     *         ...
     *     });
     * </pre>
     *
     * @ngInject
     */
    function unsubscribe($resource, defaults) {
        return $resource(defaults.API_BASE_PATH + 'unsubscribe/:uuid', { uuid:'@uuid' });
    }

    angular
        .module('ntb-fe')
        .factory('unsubscribe', unsubscribe);

})(window.angular);