(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:employmentService
     * @description
     *
     * Employment details service
     *
     * @requires ntb-fe.factory:employment
     *
     * @example
     *
     * <pre>
     *     function SomeController(employmentService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function employmentService($q, $filter, employment, dataModelService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:employmentService
         * @name ntb-fe.service:employmentService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         *
         * @param tmpData
         * @returns {Object}
         */
        service.checkUserData = function(tmpData) {
            return $filter('employmentDataFilter')(tmpData, dataModelService.getBlankModelData('employment'));
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:employmentService
         * @name service.submitEmploymentDetails
         * @description
         * Submit the cleanModel Object via the personal factory and handle the response
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.submitEmploymentDetails = function(data) {
            return $q(function(resolve, reject) {
                employment.save(data, function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };

    }

    angular
        .module('ntb-fe')
        .service('employmentService', employmentService);

})(window.angular);