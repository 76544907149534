(function (angular) {

    'use strict';

    /**
     * @ngdoc directive
     * @name ntb-fe.directive:dateFieldGroup
     * @description
     * Controls the date field group
     *
     * @restrict A
     *
     * @ngInject
     *
     * @returns {Object} Directive
     */
    function dateFieldGroup($rootScope, analyticsService) {

        function link(scope, element) {

            var inputs = element.find('input'),
                day = angular.element(inputs[0]),
                month = angular.element(inputs[1]),
                year = angular.element(inputs[2]),
                isTabbing = false;

            /**
             * @private
             * @param keyCode
             * @returns {Boolean} Boolean
             */
            function checkNumberKey(keyCode) {
                return keyCode && ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105));
            }

            /**
             * @private
             */
            function checkFocus(type) {
                if (day.val().length === 0 && month.val().length === 0 && year.val().length === 0) {
                    day[0].focus();
                    scope.form[scope.applicant + '_dob' + type].$setUntouched();
                    scope.$digest();
                    // Trigger a full digest to ensure validation is correct now.
                    $rootScope.$digest();
                }
            }

            /**
             * @private
             */
            function checkTabbing(event) {
                if (angular.isDefined(event.keyCode)) {
                    isTabbing = event.keyCode === 9;
                }
            }

            /**
             * @private
             * @param value
             * @param num
             * @returns {String} String representation of numerical input
             */
            function maxVal(value, num) {
                var zero = value.toString().substr(0, 1) === '0',
                    parsedValue;

                value = parseFloat(value.toString().replace(/[^0-9._-]/g, ''));
                parsedValue = value > num ? '' : value;

                return isNaN(parsedValue) ? '' : zero ? '0' + (parsedValue ? parsedValue.toString() : '') : parsedValue.toString();
            }

            /**
             * DAY inout field
             */
            day.on('keyup keydown focus', function(event) {

                checkTabbing(event);

                if (isTabbing) {
                    return;
                }

                switch (event.type) {
                    case 'keyup':
                        if (event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 8) {
                            day.val(maxVal(day.val(), 31));
                            if (checkNumberKey(event.keyCode) && day.val().length === 2) {
                                month[0].focus();
                            }
                        }
                        break;
                    case 'keydown':
                        if (event.keyCode === 39 && day[0].selectionStart === 2) {
                            month[0].focus();
                        }
                        break;
                    case 'focus':
                        analyticsService.trackFormInteraction('dob_day');
                        break;
                }
            });

            /**
             * MONTH input field
             */
            month.on('keyup keydown focus', function(event) {

                checkTabbing(event);

                if (isTabbing) {
                    return;
                }

                switch (event.type) {
                    case 'keyup':
                        if (event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 8) {
                            month.val(maxVal(month.val(), 12));
                            if (checkNumberKey(event.keyCode) && month.val().length === 2) {
                                year[0].focus();
                            }
                        }
                        break;
                    case 'keydown':
                        if ((event.keyCode === 8 && month.val().length === 0) || (event.keyCode === 37 && month[0].selectionStart === 0)) {
                            day[0].focus();
                        }
                        if (event.keyCode === 39 && month[0].selectionStart === 2) {
                            year[0].focus();
                        }
                        break;
                    case 'focus':
                        checkFocus('Month');
                        analyticsService.trackFormInteraction('dob_month');
                        break;
                }
            });

            /**
             * YEAR input field
             */
            year.on('keyup keydown focus', function(event) {

                checkTabbing(event);

                if (isTabbing) {
                    return;
                }

                switch (event.type) {
                    case 'keyup':
                        if (event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 8) {
                            year.val(maxVal(year.val(), new Date().getFullYear()));
                        }
                        break;
                    case 'keydown':
                        if ((event.keyCode === 8 && year.val().length === 0) || (event.keyCode === 37 && year[0].selectionStart === 0)) {
                            month[0].focus();
                        }
                        break;
                    case 'focus':
                        checkFocus('Year');
                        analyticsService.trackFormInteraction('dob_year');
                        break;
                }
            });
        }

        return {
            restrict: 'A',
            scope: {
                applicant: '@',
                label: '@',
                hasError: '=',
                formError: '=',
                form: '=',
                dateOfBirth: '='
            },
            link: link,
            templateUrl: 'date-field-group.template.html'
        };
    }

    angular
        .module('ntb-fe')
        .directive('dateFieldGroup', dateFieldGroup);

})(window.angular);
