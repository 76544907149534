(function() {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:analyticsDataService
     * @description
     *
     * Analytics service
     *
     * @requires ntb-fe.service:dataModelService
     * @requires ntb-fe.constant:defaults
     *
     * @example
     *
     * <pre>
     *     function SomeController(analyticsDataService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function analyticsDataService($log, $state, $filter, dataModelService, defaults) {

        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @private
         * @param section
         * @returns {*}
         */
        function getBaseAnalyticData(section) {

            if (section === 'resume') {
                return {
                    adoPageName: 'bank>ntb>resume'
                };
            }
            if (section === 'unsubscribe') {
                return {
                    adoPageName: 'bank>ntb>email unsubscribe'
                };
            }

            var stateItem = defaults.JOURNEY_STATES.filter(function (item) {
                return item.name === section;
            });

            return angular.isDefined(stateItem[0]) ? angular.copy(stateItem[0].analytics) : void 0;
        }

        /**
         * @param {String} section Current view/section
         * @param {Array} formTrackingData Form racking data form main analytics service
         * @returns {Object}
         */
        service.getAnalyticsData = function (section, formTrackingData) {

            /* jshint maxcomplexity: 19 */

            var modelData,
                analyticsObj = {};
            
            function getIncomeAnalytics() {
                modelData = dataModelService.getModelData('income');
                analyticsObj = {
                    'adoIncomeBefore': modelData.interactions.primary.incomeBeforeTax,
                    'adoIncomeBeforePeriod': modelData.interactions.primary.incomeBeforeTaxInterval,
                    'adoIncomeAfter': modelData.interactions.primary.incomeAfterTax,
                    'adoIncomeAfterPeriod': modelData.interactions.primary.incomeAfterTaxInterval,
                    'adoRetirementAge': modelData.primary.retirementAge || 'NOT REQUIRED',
                    'adoOverdraft': modelData.overdraft
                };
            }

            switch (section) {
                case 'resume':
                    analyticsObj = {
                        'adoUaid': $state.params.uuid
                    };
                    break;
                case 'start':
                    // EMPTY
                    break;
                case 'registration':
                    modelData = dataModelService.getModelData('registration');
                    analyticsObj = {
                        'adoAccountType': modelData.userChoices.accountType === 'sole' ? 'Sole Account' : 'Joint Account',
                        'adoCountry': modelData.interactions.primary.residence
                    };
                    break;
                case 'contactDetails':
                    modelData = dataModelService.getModelData('registration');
                    analyticsObj = {
                        'adoAge': dataModelService.getApplicantAge('primary'),
                        'adoPreviousName': modelData.primary.previousNames && modelData.primary.previousNames.length ? modelData.primary.previousNames[0].firstName + ' ' + modelData.primary.previousNames[0].surname : 'No Previous Name'
                    };
                    break;
                case 'personalDetails':
                    modelData = dataModelService.getModelData('contactDetails');

                    if (angular.isUndefined(modelData.primary.currentAddress.address)) {
                        break;
                    }

                    analyticsObj = {
                        'adoPostcode': modelData.primary.currentAddress.address.postcode,
                        'adoTown': modelData.primary.currentAddress.address.address4,
                        'adoPropertyTime': modelData.primary.currentAddress.yearsAtAddress + ' years, ' + modelData.primary.currentAddress.monthsAtAddress + ' months',
                        'adoResidentialStatus': modelData.primary.residentialStatus,
                        'adoMarketing': 'email: ' + modelData.primary.marketingOptions.email + ', post: ' + modelData.primary.marketingOptions.post + ', sms: ' + modelData.primary.marketingOptions.sms + ', telephone: ' + modelData.primary.marketingOptions.telephone + ', other: ' + modelData.primary.marketingOptions.thirdParty
                    };
                    break;
                case 'employment':
                    modelData = dataModelService.getModelData('personalDetails');
                    analyticsObj = {
                        'adoMaritalStatus': modelData.primary.maritalStatus,
                        'adoDependents': modelData.primary.dependents,
                        'adoNationality': modelData.primary.nationality,
                        'adoTaxUs': modelData.primary.taxDetails.usTaxResident,
                        'adoCitizenUS': modelData.primary.taxDetails.usCitizen,
                        'adoTaxResident': modelData.primary.taxDetails.taxResOutsideUKOrUSCit,
                        'adoForeignTaxResident': modelData.primary.taxDetails.islandsTaxResident
                    };
                    break;
                case 'income':
                    modelData = dataModelService.getModelData('employment');
                    analyticsObj = {
                        'adoEmploymentStatus': modelData.primary.status,
                        'adoJobTitle': modelData.primary.jobTitle
                    };
                    break;
                case 'overdraft':

                    getIncomeAnalytics();

                    break;
                case 'rewards':

                    if (dataModelService.hasRequestedOverdraft()) {
                        modelData = dataModelService.getModelData('overdraft');
                        analyticsObj = {
                            'adoMortgage': modelData.interactions.primary.mortgageOrRent,
                            'adoMortgagePeriod': modelData.interactions.primary.mortgageOrRentInterval,
                            'adoCouncilTax': modelData.interactions.primary.councilTax,
                            'adoCouncilTaxPeriod': modelData.interactions.primary.councilTaxInterval,
                            'adoUtility': modelData.interactions.primary.utilityBills,
                            'adoUtilityPeriod': modelData.interactions.primary.utilityBillsInterval,
                            'adoTvTel': modelData.interactions.primary.tvAndBroadband,
                            'adoTvTelPeriod': modelData.interactions.primary.tvAndBroadbandInterval,
                            'adoMob': modelData.interactions.primary.mobilePhone,
                            'adoMobPeriod': modelData.interactions.primary.mobilePhoneInterval,
                            'adoLiving': modelData.interactions.primary.livingCosts,
                            'adoLivingPeriod': modelData.interactions.primary.livingCostsInterval,
                            'adoOverdraft': modelData.overdraftAmount
                        };
                    } else {

                        getIncomeAnalytics();

                    }
                    break;
                case 'bankDetails':
                    modelData = dataModelService.getModelData('rewards');
                    analyticsObj = {
                        'adoRewardsType': modelData.rewardsType,
                        'adoChosenCharity': modelData.chosenCharity
                    };
                    break;
                case 'switching':
                    modelData = dataModelService.getModelData('bankDetails');
                    analyticsObj = {
                        'adoExistingCurrentAccount': modelData.interactions.existingCurrentAccount
                    };
                    break;
                case 'memorableWord':
                    modelData = dataModelService.getModelData('switching');
                    analyticsObj = {
                        'adoSwitch': !!modelData.switching,
                        'adoSwitchChoice': modelData.switching ? modelData.fullSwitch ? 'Full' : 'Partial' : 'No Switch'
                    };
                    break;
            }

            var copiedTrackingData = angular.copy(formTrackingData);
            analyticsObj['adoFormField'] = copiedTrackingData.join('>');
            analyticsObj['adoDetailFormField'] = $filter('analyticsDetailFormFieldFilter')(copiedTrackingData);

            $log.info('We are including for', section, '-', analyticsObj);

            return angular.extend({}, getBaseAnalyticData(section), defaults.BASE_ANALYTICS, analyticsObj);
        };
    }

    angular
        .module('ntb-fe')
        .service('analyticsDataService', analyticsDataService);

})(window.angular);