(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:httpInterceptor
     * @description
     * **Factory**
     *
     * Includes Authentication header for all requests.
     *
     * Intercepts all $http error responses.
     *
     * @return {object} responseError function
     *
     * @example
     *
     * <pre>
     *     $httpProvider.interceptors.push('httpInterceptor');
     * </pre>
     *
     * @ngInject
     */
    function httpInterceptor($q, $injector, dataModelService) {
        return {
            request: function(config) {

                // Default timeout
                config.timeout = 30000;

                var copy = angular.extend({}, config);

                if (copy.method === 'POST' && !dataModelService.isJointApplication() && copy.data) {

                    if (copy.data.secondary) {
                        copy.data.secondary = void 0;
                        delete copy.data.secondary;
                    }
                    if (copy.data.interactions && copy.data.interactions.secondary) {
                        copy.data.interactions.secondary = void 0;
                        delete copy.data.interactions.secondary;
                    }
                }

                return(copy);
            },
            responseError: function(error) {

                var state = $injector.get('$state');

                if (error.status === 401 && state.current.name !== 'resume') {
                    if (dataModelService.hasStoredData()) {
                        state.go('resume', {}, { notify: true, reload: true });
                    } else {
                        state.go('start', {}, { notify: true, reload: true });
                    }
                }

                return $q.reject(error);
            }
        };
    }

    angular
        .module('ntb-fe')
        .factory('httpInterceptor', httpInterceptor);

})(window.angular);
