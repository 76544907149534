(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.constant:defaults
     * @requires ntb-fe.service:dataModelService
     * @requires ntb-fe.service:registrationService
     * @requires ntb-fe.service:resumeService
     *
     * @name ntb-fe.controller:RegistrationController
     * @description
     * Preamble controller
     *
     * @example
     * *RegistrationController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'RegistrationController as registration'
     * </pre>
     *
     * @ngInject
     */
    function RegistrationController($log, $rootScope, $state, $localStorage, defaults, dataModelService, registrationService, resumeService, analyticsService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:RegistrationController
         * @name ntb-fe.controller:RegistrationController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:RegistrationController
         * @name vm.notifications
         * @description
         * Notifications model to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="registration.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError              : false,
            serverError               : false,
            primaryApplicantAgeError  : false,
            secondaryApplicantAgeError: false,
            existingApplicant         : false,
            sendingEmail              : false,
            emailSendError            : false,
            resumeRequested           : false,
            isSubmitting              : false
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:RegistrationController
         * @name vm.userData
         * @description
         * User data model to be passed to DOM and updated with user input/selections
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('registration');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('registration');

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:RegistrationController
         * @name vm.addPreviousNameOption
         * @description
         * Submits the user data to *registrationService* and handles the response
         *
         * @param {String} applicant Primary or secondary applicant
         * @param {Boolean} reset Reset previous names before adding the new option
         *
         * @example
         * <pre>
         *     <button type="button" data-ng-click="registration.addPreviousNameOption('primary', false)">Add previous name</button>
         * </pre>
         */
        vm.addPreviousNameOption = function(applicant, reset) {

            if (reset) {
                vm.resetPreviousNames(applicant);
            }

            var prevName = angular.copy(defaults.PREVIOUS_NAME, {});
            prevName.title = vm.userData[applicant].title;
            prevName.firstName = vm.userData[applicant].firstName;
            prevName.middleName = vm.userData[applicant].middleName;
            prevName.surname = vm.userData[applicant].surname;

            vm.userData[applicant].previousNames.push(prevName);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:RegistrationController
         * @name vm.resetPreviousNames
         * @description
         * Clears all applicant previous names
         *
         * @param {String} applicant Primary or secondary applicant
         *
         * @example
         * <pre>
         *     <button type="button" data-ng-click="registration.resetPreviousNames('primary')">No previous name</button>
         * </pre>
         */
        vm.resetPreviousNames = function(applicant) {
            vm.userData[applicant].previousNames = [];
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:RegistrationController
         * @name vm.removePreviousName
         * @description
         * Removes the selected applicant previous name
         *
         * @param {String} applicant Primary or secondary applicant
         * @param {Number} index Chosen primary or secondary applicant Array index
         *
         * @example
         * <pre>
         *     <button type="button" data-ng-click="registration.removePreviousName('primary', 1)">Remove this name</button>
         * </pre>
         */
        vm.removePreviousName = function(applicant, index) {

            // Switch to 'No previous name(s)'
            if (vm.userData[applicant].previousNames.length === 1) {
                vm.userData.interactions[applicant].previousName = false;
            }

            vm.userData[applicant].previousNames.splice(index, 1);
        };

        /**
         *
         * @param item
         */
        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:RegistrationController
         * @name vm.checkEmail
         * @description
         * Passes the user's email address to the BE to determine if there's an existing application for this user
         *
         * @param {Boolean} valid Is the supplied email valid
         */
        vm.resumeEmail = function(valid) {

            if (!valid || vm.notifications.sendingEmail) {
                return;
            }

            analyticsService.trackEvent('NTB - Email Me', 'NTB - Email Me');
            $localStorage.put('emailAddress', vm.userData.primary.emailAddress);

            vm.notifications.sendingEmail = true;

            resumeService.request(vm.userData.primary.emailAddress).then(function() {
                vm.notifications.sendingEmail = false;
                vm.notifications.emailSendError = false;
                vm.notifications.resumeRequested = true;
            }, function(error) {
                $log.error(error);
                vm.notifications.sendingEmail = false;
                vm.notifications.emailSendError = true;
                vm.notifications.resumeRequested = false;
            });
        };

        vm.resetExistingEmail = function() {
            vm.notifications.existingApplicant = false;
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:RegistrationController
         * @name vm.submitUserData
         * @description
         * Submits the user data to *registrationService* and handles the response
         *
         * @param {Object} form Form submitted
         *
         * @example
         * <pre>
         *     <form name="registrationForm" data-ng-submit="registration.submitUserData(registrationForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         */
        vm.submitUserData = function(form) {

            vm.notifications.hasFormError = !form.$valid || vm.notifications.primaryApplicantAgeError || vm.notifications.secondaryApplicantAgeError;

            // Registration
            if (vm.notifications.hasFormError) {

                if (!form.$valid) {
                    analyticsService.trackFormErrors(form);
                }

                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;

            resumeService.checkExistingEmail(vm.userData.primary.emailAddress).then(function(response) {

                vm.notifications.existingApplicant = response;

                if (vm.notifications.existingApplicant) {
                    vm.notifications.isSubmitting = false;
                    return;
                }

                // Registration - CPD Ignore
                $localStorage.put('emailAddress', vm.userData.primary.emailAddress);

                dataModelService.updateModel('registration', vm.userData);
                analyticsService.addRoute('contact-details');
                analyticsService.finishFormTracking();

                registrationService.submitRegistration(vm.userData)
                    .then(function() {
                        $state.go('contactDetails');
                    }, function(error) {
                        $log.error('RegistrationController:', error);
                        vm.notifications.serverError = true;
                        vm.notifications.isSubmitting = false;
                        analyticsService.trackServerError('submitRegistration: ' + error.status);
                    });

            }, function(error) {
                vm.notifications.serverError = true;
                vm.notifications.isSubmitting = false;
                analyticsService.trackServerError('checkExistingEmail: ' + error.status);
            });
        };

        // IIFE init
        (function() {

            analyticsService.startFormTracking();

            function prospectDataHandler(newVal) {

                vm.notifications.primaryApplicantAgeError = registrationService.calcAge(newVal.primary.dateOfBirth);

                if (newVal.secondary && newVal.secondary.dateOfBirth) {
                    vm.notifications.secondaryApplicantAgeError = registrationService.calcAge(newVal.secondary.dateOfBirth);
                }
            }

            var prospectDataWatcher = $rootScope.$watch(angular.bind(vm, function() {
                return vm.userData;
            }), prospectDataHandler, true);

            $rootScope.$on('$destroy', function() {
                prospectDataWatcher();
            });
        })();
    }

    angular
        .module('ntb-fe')
        .controller('RegistrationController', RegistrationController);

})(window.angular);
