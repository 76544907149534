(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:formErrorsFilter
     * @description
     * Parses the
     *
     * @example
     * <pre>
     *     $filter('dateOfBirth')(date);
     * </pre>
     *
     * @ngInject
     */
    function formErrorsFilter($log) {
        return function (form) {

            if (!form || form.$valid || !form.$error) {
                $log.debug('INVALID FORM');
                return '';
            }

            var errorsArr = [];

            angular.forEach(form.$error.required, function(field) {
                if (field.$invalid){
                    errorsArr.push(field.$name + '_empty');
                }
            });

            angular.forEach(form.$error.pattern, function(field) {
                if (field.$invalid){
                    errorsArr.push(field.$name + '_invalid');
                }
            });

            return errorsArr.join(',');
        };
    }

    angular
        .module('ntb-fe')
        .filter('formErrorsFilter', formErrorsFilter);

})(window.angular);