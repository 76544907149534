(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:resumeService
     * @description
     *
     * Resume service
     *
     * @require ntb-fe.applicantLogin
     *
     * @example
     *
     * <pre>
     *     function SomeController(resumeService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function resumeService($log, $q, $filter, dataModelService, resume) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:resumeService
         *
         * @name service
         * @description
         * Verbose 'this' as service
         *
         * @type {resumeService}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:resumeService
         * @name service.request
         * @description
         * Resume UUID check request
         *
         * @param {Object} email Email address
         */
        service.request = function(email) {

            return $q(function(resolve, reject) {
                resume.request({}, { email: email }, function() {
                    resolve();
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:resumeService
         * @name service.check
         * @description
         * Resume UUID check request
         *
         * @param {Object} uuid Resume UUID
         */
        service.check = function(uuid) {

            return $q(function(resolve, reject) {
                resume.check({}, { uuid: uuid }, function() {
                    resolve();
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:resumeService
         * @name service.authenticate
         * @description
         * Authenticate the user
         *
         * @param {Object} userData User data
         */
        service.authenticate = function(userData) {

            var copy = angular.copy(userData, {});
            copy.dateOfBirth = $filter('dateOfBirthObject')(copy.dateOfBirth);

            $log.debug('authenticate:', copy);

            return $q(function(resolve, reject) {
                resume.authenticate({}, copy, function(response) {
                    dataModelService.checkResumeData(response);
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:resumeService
         * @name checkExistingEmail
         * @description
         * Check for existing application based on supplied email address
         *
         * @param {String} emailAddress User data Object
         */
        service.checkExistingEmail = function(emailAddress) {

            return $q(function(resolve, reject) {
                resume.emailCheck({}, { emailAddress: emailAddress }, function(response) {
                    resolve(response.status === 'ACTIVE_APPLICATION');
                }, function(error) {
                    reject(error);
                });
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('resumeService', resumeService);

})(window.angular);