(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:memorableWordService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:MemorableWordController
     * @description
     * Contact details controller
     *
     * @example
     * *MemorableWordController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'MemorableWordController as contact'
     * </pre>
     *
     * @ngInject
     */
    function MemorableWordController($log, $window, $rootScope, memorableWordService, dataModelService, analyticsService, timeoutService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:MemorableWordController
         * @name ntb-fe.controller:MemorableWordController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:MemorableWordController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError      : false,
            serverError       : false,
            isJointApplication: dataModelService.isJointApplication(),
            isSubmitting      : false,
            primary           : {
                firstName: dataModelService.getFirstName('primary')
            },
            secondary         : {
                firstName: dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : ''
            }
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:MemorableWordController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('memorableWord');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('memorableWord');

        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:MemorableWordController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="contactForm" data-ng-submit="contact.submitUserData(contactForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {

            vm.notifications.hasFormError = !form.$valid;

            // Memorable Word
            if (vm.notifications.hasFormError) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // Memorable word - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;

            // Accept terms
            vm.userData.fscs = true;

            dataModelService.updateModel('memorableWord', vm.userData);
            analyticsService.finishFormTracking();

            var msn = dataModelService.getModelData('registration').msn;

            memorableWordService.submitMemorableWord(vm.userData)
                .then(function(response) {

                    $log.debug('APPLICATION COMPLETE UAID:', response.uaid);

                    // Manually update the analytics here
                    analyticsService.addAnalyticsData({
                        'adoUaid': response.uaid
                    });
                    analyticsService.updateView('memorableWord');

                    // Redirect after digest
                    $rootScope.$applyAsync(function() {

                        // Clear timers and session
                        timeoutService.isInSession = false;
                        timeoutService.cancelTimeout();

                        // Clear data model
                        dataModelService.clearDataModel();

                        $window.open('http://www.co-operativebank.co.uk/currentaccounts/currentaccount/confirmation?id=' + response.uaid + (msn ? '&media=' + msn : '') + '&er=NTB', '_self');
                    });

                }, function(error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitMemorableWord: ' + error.status);
                });
        };

        (function() {
            analyticsService.startFormTracking();
        })();
    }

    angular
        .module('ntb-fe')
        .controller('MemorableWordController', MemorableWordController);

})(window.angular, window, history);
