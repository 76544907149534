(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:intervalAmountService
     * @description
     *
     * Interval Amount Service
     *
     * @example
     *
     * <pre>
     *     function SomeController(intervalAmountService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function intervalAmountService() {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:intervalAmountService
         * @name ntb-fe.service:intervalAmountService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:intervalAmountService
         * @name service.calculateMonthlyValue
         * @description
         * Calculates the monthly value based on the supplied interval
         *
         * @param {Number} value Value to be calculated
         * @param {String} interval Interval [Weekly|Monthly|Yearly]
         *
         * @returns {Number} Calculated Monthly value
         */
        service.calculateMonthlyValue = function(value, interval) {

            var calculatedValue = parseInt(value, 10);

            if (!calculatedValue) {
                return 0;
            }

            switch(interval) {
                case 'Weekly':
                    calculatedValue = calculatedValue * 52 / 12;
                    break;
                case 'Four Weekly':
                    calculatedValue = (calculatedValue / 4) * 52 / 12;
                    break;
                case 'Yearly':
                    calculatedValue = calculatedValue / 12;
                    break;
            }

            return calculatedValue;
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:intervalAmountService
         * @name service.calculateYearlyValue
         * @description
         * Calculates the yearly value based on the supplied interval
         *
         * @param {Number} value Value to be calculated
         * @param {String} interval Interval [Weekly|Monthly|Yearly]
         *
         * @returns {Number} Calculated Yearly value
         */
        service.calculateYearlyValue = function(value, interval) {

            var calculatedValue = parseInt(value, 10);

            if (!calculatedValue) {
                return 0;
            }

            switch(interval) {
                case 'Weekly':
                    calculatedValue = calculatedValue * 52;
                    break;
                case 'Four Weekly':
                    calculatedValue = (calculatedValue / 4) * 52;
                    break;
                case 'Monthly':
                    calculatedValue = calculatedValue * 12;
                    break;
            }

            return calculatedValue;
        };
    }

    angular
        .module('ntb-fe')
        .service('intervalAmountService', intervalAmountService);

})(window.angular, window.moment);
