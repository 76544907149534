(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:bankDetails
     * @description
     * **Factory**
     *
     * Bank details factory
     *
     * @requires ntb-fe.constant:defaults
     * @return {Object} ngResource instance
     *
     * @example
     *
     * <pre>
     *     bankDetails.save({}, function(response) {
     *         ...
     *     }, function(error) {
     *         ...
     *     });
     * </pre>
     *
     * @ngInject
     */
    function bankDetails($resource, defaults) {
        return $resource(defaults.API_BASE_PATH + 'secure/bank-details');
    }

    angular
        .module('ntb-fe')
        .factory('bankDetails', bankDetails);

})(window.angular);