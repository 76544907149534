(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:employment
     * @description
     * **Factory**
     *
     * Employment details factory
     *
     * @requires ntb-fe.constant:defaults
     * @return {Object} ngResource instance
     *
     * @example
     *
     * <pre>
     *     employment.save({}, function(response) {
     *         ...
     *     }, function(error) {
     *         ...
     *     });
     * </pre>
     *
     * @ngInject
     */
    function employment($resource, defaults) {
        return $resource(defaults.API_BASE_PATH + 'secure/employment');
    }

    angular
        .module('ntb-fe')
        .factory('employment', employment);

})(window.angular);