(function(angular, window) {

    'use strict';

    /**
     * @ngdoc controller
     * @name ntb-fe.controller:ProgressController
     * @description
     * Progress controller
     *
     * Responds to the *ui.router* event *$stateChangeSuccess* and updates the progress based on the current state name
     *
     * @requires ntb-fe.constant:defaults
     * @requires ntb-fe.service:dataModelService
     *
     * @example
     * *ProgressController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'ProgressController as progress'
     * </pre>
     *
     * @ngInject
     */
    function ProgressController($log, $rootScope, $state, $interval, dataModelService, analyticsService, defaults, appInfo) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ProgressController
         * @this Scope
         * @name vm
         * @description
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this,
            currentSection,
            focusInterval;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ProgressController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-class="'fill-' + progress.notifications.progress">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            progress   : 0,
            totalProgress: defaults.JOURNEY_STATES.length - 1, // Minus overdraft and application complete
            backEnabled: false,
            versionInfo: 'JDI ntb-fe v' + appInfo.VERSION + ' (' + appInfo.BUILD + ')'
        };

        /**
         * @private
         * @param {String} stateName State name
         * @returns {Number} Progress / 100
         */
        function getProgress(stateName) {
            var stateItem = defaults.JOURNEY_STATES.filter(function(item) {
                return item.name === stateName;
            });

            return stateItem.length ? stateItem[0].progress : 0;
        }

        /**
         * @private
         * @param {String} name State name
         * @returns {boolean} Should the page be skipped
         */

        function skipPage(name) {
            switch(name) {
                case 'switching':
                    if (!dataModelService.getUserDetails('primary').accountNumber) {
                        return true;
                    }
                    break;
                case 'overdraft':
                    if (!dataModelService.hasRequestedOverdraft()) {
                        return true;
                    }
                    break;
            }
            return false;
        }

        /**
         * @private
         * @param {String} stateName State name
         * @returns {String} Previous State name
         */
        function getPrevioustate(stateName) {

            if (stateName === 'resume' && !$state.params.uuid) {
                return defaults.JOURNEY_STATES[0].name;
            }

            var index = 0;

            for (var i = 0, len = defaults.JOURNEY_STATES.length; i < len; i ++) {
                if (defaults.JOURNEY_STATES[i].name === stateName) {
                    index = i;
                }
            }

            if (skipPage(defaults.JOURNEY_STATES[index - 1].name)) {
                return defaults.JOURNEY_STATES[index - 2].name;
            }

            return defaults.JOURNEY_STATES[index - 1].name;
        }

        /**
         * @private
         * @description
         * IIFE init method.
         *
         * @type {Function}
         */
        (function() {

            function updateFocus() {

                var attemptCount = 0;

                focusInterval = $interval(function() {

                    var focusObj = window['document'].getElementsByClassName('has-form-error');
                    if (focusObj && focusObj.focus) {
                        focusObj.attr('tabIndex', -1).focus();
                        $interval.cancel(focusInterval);
                    }

                    if (attemptCount === 10) {
                        $interval.cancel(focusInterval);
                    }

                    attemptCount++;

                }, 500);
            }

            /**
             *
             */
            var stateSuccessWatcher = $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {

                    currentSection = toState.name;

                    $log.debug('Previous State:', fromState.name, 'params:', fromParams);
                    $log.debug('Current State:', currentSection, 'params:', toParams);

                    vm.notifications.progress = getProgress(currentSection);
                    vm.notifications.backEnabled = currentSection !== 'start' && !$state.params.uuid;

                    vm.goBack = function() {
                        $state.go(getPrevioustate(currentSection));
                    };

                    $rootScope.$applyAsync(function() {
                        window.scrollTo(0, 0);
                    });

                    analyticsService.updateView(toState.name);

                }),
                formErrorWatcher = $rootScope.$on('hasFormError', function() {
                    $rootScope.$applyAsync(function() {
                        window.scrollTo(0, 0);
                        updateFocus();
                    });
                }),
                destroyWatcher = $rootScope.$on('$destroy', function() {
                    $log.debug('ProgressController: Destroy watchers');
                    stateSuccessWatcher();
                    formErrorWatcher();
                    destroyWatcher();
                });

        })();
    }

    angular
        .module('ntb-fe')
        .controller('ProgressController', ProgressController);

})(window.angular, window);