(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:registrationData
     * @description
     * Removes secondary applicant from payload if redundant.
     *
     * @example
     * <pre>
     *     $filter('registrationData')(data);
     * </pre>
     *
     * @ngInject
     */
    function registrationData($filter) {

        return function(data) {
            var copy = angular.copy(data, {}),
                parsedPrimaryDob,
                parsedSecondaryDob;

            if (copy.secondary && !copy.secondary.title) {
                delete copy.secondary;
            }

            if (copy.primary && copy.primary.dateOfBirth) {
                parsedPrimaryDob = $filter('dateOfBirth')(copy.primary.dateOfBirth).split('/');

                copy.primary.dateOfBirth = {
                    day: parseInt(parsedPrimaryDob[0], 10),
                    month: parseInt(parsedPrimaryDob[1], 10),
                    year: parseInt(parsedPrimaryDob[2], 10)
                };
            }

            if (copy.secondary && copy.secondary.dateOfBirth) {
                parsedSecondaryDob = $filter('dateOfBirth')(copy.secondary.dateOfBirth).split('/');

                copy.secondary.dateOfBirth = {
                    day: parseInt(parsedSecondaryDob[0], 10),
                    month: parseInt(parsedSecondaryDob[1], 10),
                    year: parseInt(parsedSecondaryDob[2], 10)
                };
            }

            return copy;
        };
    }

    angular
        .module('ntb-fe')
        .filter('registrationData', registrationData);

})(window.angular);