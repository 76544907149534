(function(angular, moment) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:momentService
     * @description
     *
     * Moment service
     * Provides and interface between AngularJS and moment.js
     *
     * @example
     *
     * <pre>
     *     function SomeController(momentService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function momentService() {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:momentService
         * @name ntb-fe.service:momentService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * Modified version of Rob Dawson's preciseDiff:
         * @see https://github.com/codebox/moment-precise-range
         *
         * @param d1
         * @param d2
         * @returns {*}
         */
        service.preciseDiff = function(d1, d2) {

            var m1 = moment(d1),
                m2 = moment(d2);

            // Ensure that the calculated date is NOT before the chosen date
            if (m2.isBefore(m1)) {
                return void 0;
            }

            var yDiff = m2.year() - m1.year(),
                mDiff = m2.month() - m1.month(),
                dDiff = m2.date() - m1.date();

            if (dDiff < 0) {
                var daysInLastFullMonth = moment(m2.year() + '-' + (m2.month() + 1), 'YYYY-MM').subtract(1, 'M').daysInMonth();
                if (daysInLastFullMonth < m1.date()) { // 31/01 -> 2/03
                    dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
                } else {
                    dDiff = daysInLastFullMonth + dDiff;
                }
                mDiff--;
            }

            if (mDiff < 0) {
                mDiff = 12 + mDiff;
                yDiff--;
            }

            return {
                years : yDiff,
                months: mDiff,
                days  : dDiff
            };
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:momentService
         * @name service.calculateAge
         * @description
         * Calculate the applicant age based on supplied DOB
         *
         * @param {String} dob Date of birth s DD/MM/YYYY
         * @returns {Number} Age in years
         */
        service.calculateAge = function(dob) {
            return moment().diff(moment(dob, 'DD/MM/YYYY'), 'years');
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:momentService
         * @name service.calculateAgeAtDate
         * @description
         * Calculate the applicant age at a certain date based on supplied DOB and Month, Year
         *
         * @param {String} dob Date of birth as String (DD/MM/YYYY)
         * @param {Number} month Month
         * @param {Number} year Year
         * @returns {Object} Age at date
         */
        service.calculateAgeAtDate = function(dob, month, year) {
            return service.preciseDiff(moment(dob, 'DD/MM/YYYY'), moment([Number(year), Number(month), 1]));
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:momentService
         * @name service.isMonthBeforeDob
         * @description
         * Is the supplied month
         *
         * @param {Object} dob Date of birth Object
         * @param {String} month Month
         * @param {String} year Year
         * @returns {Boolean} isMonthBeforeDob
         */
        service.isMonthBeforeDob = function(dob, month, year) {
            var momentDob = moment(dob, 'DD/MM/YYYY'),
                monthNum = parseInt(month, 10),
                yearNum = parseInt(year, 10);
            return (yearNum < momentDob.year()) || (yearNum === momentDob.year() && monthNum < momentDob.month());
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:momentService
         * @name service.calculateAddressTime
         * @description
         * Calculate the time at address based on supplied month and year
         *
         * @param {String} month Month
         * @param {String} year Year
         * @returns {Object} Years and months at address
         */
        service.calculateAddressTime = function(month, year) {
            return service.preciseDiff(moment([Number(year), Number(month), 1]), moment());
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:momentService
         * @name service.calculatePreviousAddressTime
         * @description
         * Calculate the time at the previous address address based on supplied month and year of both addresses
         *
         * @param {String} currentAddressMonth Month
         * @param {String} currentAddressYear Year
         * @param {String} previousAddressMonth Month
         * @param {String} previousAddressYear Year
         * @returns {Object} Years and months at address
         */
        service.calculatePreviousAddressTime = function(currentAddressMonth, currentAddressYear, previousAddressMonth, previousAddressYear) {
            return service.preciseDiff(moment([Number(previousAddressYear), Number(previousAddressMonth), 1]), moment([Number(currentAddressYear), Number(currentAddressMonth), 1]));
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:momentService
         * @name service.calculateEmploymentTime
         * @description
         * Calculate the time at employment based on supplied month and year
         *
         * @param {String} month Month
         * @param {String} year Year
         * @returns {Object} Years and months at address
         */
        service.calculateEmploymentTime = function(month, year) {
            return service.preciseDiff(moment([Number(year), Number(month), 1]), moment());
        };

        /**
         *
         * @param date
         */
        service.getLongDate = function(date) {
            return moment(date, 'DD/MM/YYYY').format('Do MMMM YYYY');
        };
    }

    angular
        .module('ntb-fe')
        .service('momentService', momentService);

})(window.angular, window.moment);
