(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:momentService
     * @requires ntb-fe.service:bankDetailsService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:BankDetailsController
     * @description
     * Contact details controller
     *
     * @example
     * *BankDetailsController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'BankDetailsController as bankDetails'
     * </pre>
     *
     * @ngInject
     */
    function BankDetailsController($log, $state, $rootScope, momentService, bankDetailsService, dataModelService, analyticsService, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:BankDetailsController
         * @name ntb-fe.controller:BankDetailsController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:BankDetailsController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="bankDetails.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError : false,
            serverError  : false,
            isSubmitting : false,
            futureDate   : false,
            dateBeforeDob: false,
            hasDateError : false,
            overMaxYears : false,
            firstName    : dataModelService.getFirstName('primary')
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:BankDetailsController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('bankDetails');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('bankDetails');

        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:BankDetailsController
         * @name vm.reset
         * @description
         * Reset the user input data
         *
         * @example
         * <pre>
         *     <button data-ng-click="bankDetails.reset()">Reset</button>
         * </pre>
         *
         * @type {Function}
         */
        vm.reset = function() {
            var tmpData = dataModelService.getBlankModelData('bankDetails');
            vm.userData = angular.copy(tmpData);

            $rootScope.$applyAsync(function() {
                vm.notifications.hasDateError = false;
                vm.userData.interactions.accountOpened.month = void 0;
                vm.userData.interactions.accountOpened.year = void 0;
                vm.userData.interactions.existingCurrentAccount = false;
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:BankDetailsController
         * @name vm.checkTimeWithAccount
         * @description
         * Check time with account
         *
         * @example
         * <pre>
         *     <input type="number" data-ng-blur="bankDetails.checkTimeWithAccount()" />
         * </pre>
         *
         * @type {Function}
         */
        vm.checkTimeWithAccount = function() {

            $log.debug('checkTimeWithAccount:', vm.userData.interactions.accountOpened);

            var startMonth = vm.userData.interactions.accountOpened.month,
                startYear = vm.userData.interactions.accountOpened.year,
                timeWithAccount;

            if (!startMonth || !startYear) {
                return;
            }

            vm.notifications.hasDateError = false;
            timeWithAccount = momentService.calculateAddressTime(startMonth, startYear);

            // Bank details controller 
            vm.notifications.futureDate = vm.notifications.hasDateError = !timeWithAccount;
            vm.notifications.dateBeforeDob = false;
            vm.notifications.overMaxYears = false;

            if (momentService.isMonthBeforeDob(dataModelService.getDateOfBirth('primary'), startMonth, startYear)) {
                vm.notifications.hasDateError = true;
                vm.notifications.dateBeforeDob = true;
                analyticsService.trackFormErrorList('dateBeforeDob');
            }

            if (!timeWithAccount) {
                vm.notifications.hasDateError = true;
                analyticsService.trackFormErrorList('hasDateError');
                return;
            }

            if (timeWithAccount.years > defaults.MAX_BANK_ACCOUNT_YEARS) {
                vm.notifications.hasDateError = true;
                vm.notifications.overMaxYears = true;
            }

            vm.userData.timeAccountHeld = timeWithAccount;
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:BankDetailsController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="contactForm" data-ng-submit="bankDetails.submitUserData(contactForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {

            vm.notifications.hasFormError = !form.$valid;

            if (vm.notifications.hasFormError || vm.notifications.hasDateError) {
                if (vm.notifications.hasFormError) {
                    analyticsService.trackFormErrors(form);
                }
                // Bank details form errors
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // bankDetails - CPD Ignore
            vm.notifications.serverError = false;
            vm.notifications.isSubmitting = true;

            // Check dates
            vm.checkTimeWithAccount();

            if (vm.notifications.hasDateError) {
                vm.notifications.isSubmitting = false;
                return;
            }

            dataModelService.updateModel('bankDetails', vm.userData);
            analyticsService.addRoute(vm.userData.interactions.existingCurrentAccount ? 'switching' : 'memorable-word');
            analyticsService.finishFormTracking();

            bankDetailsService.submitBankDetails(vm.userData)
                .then(function() {
                    $state.go(vm.userData.interactions.existingCurrentAccount ? 'switching' : 'memorableWord');
                }, function(error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitBankDetails: ' + error.status);
                });
        };

        (function() {
            analyticsService.startFormTracking();
        })();
    }

    angular
        .module('ntb-fe')
        .controller('BankDetailsController', BankDetailsController);

})(window.angular);