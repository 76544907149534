(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:dateOfBirth
     * @description
     * Corrects the user input date of birth as date Object or String and returns (DD/MM/YYYY)
     *
     * @example
     * <pre>
     *     $filter('dateOfBirth')(date);
     * </pre>
     *
     * @ngInject
     */
    function dateOfBirthObject($filter) {

        return function(dateOfBirth) {
            var parsedDob = $filter('dateOfBirth')(dateOfBirth).split('/');

            return {
                day: parseInt(parsedDob[0], 10),
                month: parseInt(parsedDob[1], 10),
                year: parseInt(parsedDob[2], 10)
            };
        };
    }

    angular
        .module('ntb-fe')
        .filter('dateOfBirthObject', dateOfBirthObject);

})(window.angular);