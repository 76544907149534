(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:dateOfBirth
     * @description
     * Corrects the user input date of birth as date Object or String and returns (DD/MM/YYYY)
     *
     * @example
     * <pre>
     *     $filter('dateOfBirth')(date);
     * </pre>
     *
     * @ngInject
     */
    function dateOfBirth() {

        function zero(num) {
            return parseInt(num, 10) < 10 ? '0' + parseInt(num, 10).toString() : parseInt(num, 10).toString();
        }

        return function(date) {

            if (date && angular.isObject(date)) {
                if (date.day && date.month && date.year) {
                    return zero(date.day) + '/' + zero(date.month) + '/' + date.year;
                }
                else if (date instanceof Date) {
                    return zero(date.getDate()) + '/' + zero(date.getMonth() + 1) + '/' + date.getFullYear();
                }
            }

            if (date && angular.isString(date)) {
                return date.replace(/-/g, '/');
            }

            return '';
        };
    }

    angular
        .module('ntb-fe')
        .filter('dateOfBirth', dateOfBirth);

})(window.angular);