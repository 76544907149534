(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:resumePoint
     * @description
     * Determines the resume point
     *
     * @example
     * <pre>
     *     $filter('dateOfBirth')(date);
     * </pre>
     *
     * @ngInject
     */
    function resumeData($log) {

        function zero(num) {
            return parseInt(num, 10) ? parseInt(num, 10) < 10 ? '0' + parseInt(num, 10).toString() : parseInt(num, 10).toString() : void 0;
        }

        return function(resumeData) {

            if (resumeData.registration && resumeData.registration.primary && resumeData.registration.primary.dateOfBirth) {
                resumeData.registration.primary.dateOfBirth.day = zero(resumeData.registration.primary.dateOfBirth.day);
                resumeData.registration.primary.dateOfBirth.month = zero(resumeData.registration.primary.dateOfBirth.month);
            }

            if (resumeData.registration && resumeData.registration.secondary && resumeData.registration.secondary.dateOfBirth) {
                resumeData.registration.secondary.dateOfBirth.day = zero(resumeData.registration.secondary.dateOfBirth.day);
                resumeData.registration.secondary.dateOfBirth.month = zero(resumeData.registration.secondary.dateOfBirth.month);
            }

            if (resumeData.switching && resumeData.switching.interactions && resumeData.switching.interactions.acceptSwitchDate) {
                resumeData.switching.interactions.acceptSwitchDate = void 0;
            }

            if (resumeData.personalDetails && resumeData.personalDetails.primary && resumeData.personalDetails.primary.dependents) {
                resumeData.personalDetails.primary.dependents = resumeData.personalDetails.primary.dependents.toString();
            }

            if (resumeData.switching && resumeData.switching.transferDate) {
                resumeData.switching.transferDate = void 0;
            }

            if (resumeData.switching && resumeData.switching.interactions && resumeData.switching.interactions.agreement) {
                resumeData.switching.interactions.agreement = void 0;
            }

            $log.debug('Resume data:', resumeData);

            return angular.copy(resumeData, {});
        };
    }

    angular
        .module('ntb-fe')
        .filter('resumeData', resumeData);

})(window.angular);