(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:mmyyFilter
     * @description
     * Corrects the user input date of birth as date Object or String and returns (DD/MM/YYYY)
     *
     * @example
     * <pre>
     *     $filter('mmyyFilter')(date);
     * </pre>
     *
     * @ngInject
     */
    function mmyyFilter() {

        function zero(num) {
            return parseInt(num, 10) < 10 ? '0' + parseInt(num, 10).toString() : num.toString();
        }

        return function(date) {
            return !date || !date.year ? '' : zero(date.month + 1) + '/' + date.year.substr(2);
        };
    }

    angular
        .module('ntb-fe')
        .filter('mmyyFilter', mmyyFilter);

})(window.angular);