(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     *
     * @name ntb-fe.filter:resumePoint
     * @description
     * Determines the resume point
     *
     * @example
     * <pre>
     *     $filter('dateOfBirth')(date);
     * </pre>
     *
     * @ngInject
     */
    function resumePoint($log, defaults) {

        return function(data) {
            var journey = defaults.JOURNEY_STATES,
                visitedIndexes = [],
                resumeIndex = 0,
                resumePoint;

            for (var i in data) {
                if (data[i].uid && data[i].uid !== '') {

                    for (var j = 0, lenJ = journey.length; j < lenJ; j ++) {
                        if (journey[j].name === data[i].uid) {
                            visitedIndexes.push(j);
                        }
                    }
                }
            }

            if (visitedIndexes.length) {
                resumeIndex = Math.max.apply(0, visitedIndexes) + 1;
            }

            resumePoint = journey[resumeIndex].name;

            if (resumePoint === journey[journey.length - 1].name) {
                resumePoint = journey[resumeIndex - 1].name;
            }

            $log.debug('resumePoint filter: Resuming to', resumePoint);

            return resumePoint;
        };
    }

    angular
        .module('ntb-fe')
        .filter('resumePoint', resumePoint);

})(window.angular);