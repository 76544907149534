(function (angular) {

    'use strict';

    /**
     * @ngdoc object
     * @name ntb-fe.constant:defaults
     * @description
     * Default constant values to be used within the main ntb-fe application
     *
     * Configurable options subject to possible change
     *
     * @example
     * *defaults* is injectable as constant
     * <pre>
     *    function myFactory($resource, defaults) {
     *        return $resource(defaults.API_BASE_PATH + 'some_endpoint');
     *    }
     * </pre>
     */
    var defaults = {
        API_BASE_PATH: './api/',
        APPLICANT_MIN_AGE: 16,
        APPLICANT_MAX_AGE: 110,
        APPLICATION_TIMEOUT: 10,
        APPLICATION_TIMEOUT_BUFFER: 1,
        PREV_ADDRESS_REQUIRED_YEARS: 3,
        HIDE_RETIRMENT_AGE: 50,
        MAX_EMPLOYMENT_YEARS: 99,
        MIN_EMPLOYMENT_AGE_YEARS: 14,
        MAX_BANK_ACCOUNT_YEARS: 99,
        OVERDRAFT_AMOUNT_MIN: 100,
        OVERDRAFT_AMOUNT_MAX: 5000,
        MSN_BASE_PATH: 'https://www.co-operativebank.co.uk/assets/images/bank/msns/',
        MSN_IMG_EXT: '.png',
        MSN_COOKIE_PATH: 'msnCurrentAccount',
        UEPAD_COUNTRIES: [
            { code: 'GB', name: 'United Kingdom' }
        ],
        PREVIOUS_NAME: {
            title: void 0,
            firstName: void 0,
            middleName: void 0,
            surname: void 0
        },
        EMPTY_ADDRESS: {
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            postcode: ''
        },
        BASE_ANALYTICS: {
            adoCategory: 'Current Accounts',
            adoProducts: 'Current Account'
        },
        BASE_TRACKING_EVENT: {
            linkTrackVars: 'prop27,eVar31,events',
            linkTrackEvents: 'event18',
            prop27: void 0,
            eVar31: void 0,
            events: 'event18'
        },
        BASE_TRACKING_FORM_ERRORS: {
            linkTrackVars: 'list1,eVar35,prop2,events',
            linkTrackEvents: 'event21',
            eVar35: void 0,
            list1: void 0,
            events: 'event21'
        },
        BASE_TRACKING_SERVER_ERRORS: {
            linkTrackVars: 'eVar26,events',
            linkTrackEvents: 'event9',
            events: 'event9'
        },
        JOURNEY_STATES: [
            {
                name: 'start',
                analytics: {
                    adoPageName: 'bank>ntb>application',
                    adoEvents: 'event36'
                },
                progress: 1
            },
            {
                name: 'registration',
                analytics: {
                    adoPageName: 'bank>ntb>get started',
                    adoEvents: 'event37'
                },
                progress: 2
            },
            {
                name: 'contactDetails',
                analytics: {
                    adoPageName: 'bank>ntb>contact details',
                    adoEvents: 'event38'
                },
                progress: 3
            },
            {
                name: 'personalDetails',
                analytics: {
                    adoPageName: 'bank>ntb>personal details',
                    adoEvents: 'event40'
                },
                progress: 4
            },
            {
                name: 'employment',
                analytics: {
                    adoPageName: 'bank>ntb>employment',
                    adoEvents: 'event41'
                },
                progress: 5
            },
            {
                name: 'income',
                analytics: {
                    adoPageName: 'bank>ntb>income',
                    adoEvents: 'event42'
                },
                progress: 6
            },
            {
                name: 'overdraft',
                analytics: {
                    adoPageName: 'bank>ntb>overdraft',
                    adoEvents: 'event43'
                },
                progress: 6
            },
            {
                name: 'bankDetails',
                analytics: {
                    adoPageName: 'bank>ntb>bank details',
                    adoEvents: 'event45'
                },
                progress: 7
            },
            {
                name: 'switching',
                analytics: {
                    adoPageName: 'bank>ntb>switching',
                    adoEvents: 'event46'
                },
                progress: 8
            },
            {
                name: 'memorableWord',
                analytics: {
                    adoPageName: 'bank>ntb>almost finished',
                    adoEvents: 'event47'
                },
                progress: 9
            }
        ]
    };

    angular
        .module('ntb-fe')
        .constant('defaults', defaults);

})(window.angular);
