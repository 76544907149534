(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:timeoutService
     * @description
     *
     * Contact details service
     *
     * @requires ntb-fe.service:dataModelService
     * @requires ntb-fe.constant:defaults
     * @requires ntb-fe.factory:session
     *
     * @example
     *
     * <pre>
     *     function SomeController(timeoutService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function timeoutService($log, $q, $rootScope, $timeout, dataModelService, defaults, session) {

        /* jshint validthis: true */
        var service = this,
        /* jshint validthis: false */
            mainTimer,
            expiredTimer;

        // Use init default
        service.serverExpiry = defaults.APPLICATION_TIMEOUT;

        // In session flag
        service.isInSession = false;

        /**
         * @private
         */
        function resetTimers() {
            // $log.debug('timeoutService: Reset timers');
            $timeout.cancel(mainTimer);
            $timeout.cancel(expiredTimer);
        }

        /**
         * @private
         */
        function expiryTriggered() {

            dataModelService.clearDataModel();

            // $log.debug('timeoutService: Expiry triggered.');
            resetTimers();
            $rootScope.$broadcast('applicationTimeoutNotifier', { type: 'fatal' });
        }

        /**
         * @private
         */
        function warningTriggered() {
            // $log.info('timeoutService: Warning triggered.');
            resetTimers();
            $rootScope.$broadcast('applicationTimeoutNotifier', { type: 'warning' });
            expiredTimer = $timeout(expiryTriggered, (defaults.APPLICATION_TIMEOUT_BUFFER * 60 * 1000));
        }

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:timeoutService
         * @name service.endSession
         * @description
         * End the current session
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from session factory.
         *
         * @type {Object}
         */
        service.endSession = function() {

            service.isInSession = false;

            return $q(function(resolve, reject) {
                session.logout(null, function() {
                    resolve();
                }, function() {
                    reject();
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:timeoutService
         * @name service.cancelTimeout
         * @description
         * Reset all timers
         *
         * @type {Object}
         */
        service.cancelTimeout = function() {
            resetTimers();
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:timeoutService
         * @name service.resetTimer
         * @description
         * Reset the timers and ping the BE to keep alive
         *
         * @type {Object}
         */
        service.resetTimer = function() {

            // $log.info('timeoutService: ', mainTimer ? 'Resetting timers.' : 'Starting timers at ' + service.serverExpiry + ' minutes.');

            return $q(function(resolve, reject) {

                session.keepAlive({ time: new Date().getTime() }, function(response) {

                    // Get server timeout and minus 1.
                    service.serverExpiry = parseInt(response.sessionTimeoutMinutes, 10);

                    resetTimers();

                    service.isInSession = true;

                    $log.info('timeoutService: Reset warning timer for', service.serverExpiry - defaults.APPLICATION_TIMEOUT_BUFFER, 'minutes.');

                    mainTimer = $timeout(warningTriggered, (service.serverExpiry * 60 * 1000) - (defaults.APPLICATION_TIMEOUT_BUFFER * 60 * 1000));

                    resolve();

                }, function(error) {
                    $log.error('timeoutService: keepAlive error:', error);
                    reject(error);
                });
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('timeoutService', timeoutService);

})(window.angular);