(function (angular, window) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:analyticsService
     * @description
     *
     * Analytics service
     *
     * @requires ntb-fe.constant:defaults
     *
     * @example
     *
     * <pre>
     *     function SomeController(analyticsService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function analyticsService($log, $rootScope, $filter, $location, analyticsDataService, defaults, tealium, tealiumData) {

        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         *
         * @type {Object}
         */
        service.formTrackingData = [];
        service.currentSection = 'start';
        service.manualData = {};

        /**
         * @private
         */
        function configureViewData(section) {
            var tmpUdo = {},
                currentData = analyticsDataService.getAnalyticsData(section, service.formTrackingData);

            angular.extend(currentData, angular.copy(service.manualData));

            tmpUdo[section] = function () {
                return currentData;
            };

            tealium.setViewIdMap(tmpUdo);
            service.manualData = {};
        }

        /**
         *
         * @param data
         * @param item
         */
        function trackAnalyticsEvent(data, item) {
            var $s = window.s;

            if ($s && $s.tl) {
                angular.extend($s, data);
                $log.info('Tracking:', $s);

                $s.tl(true, 'o', item);

                // Clear data added to $s
                $rootScope.$applyAsync(function() {
                    for (var i in data) {
                        if ($s.hasOwnProperty(i)) {
                            $log.info('Deleting from $s:', i);
                            delete $s[i];
                        }
                    }
                });

            } else {
                $log.debug('High-level tracking currently unavailable');
            }
        }

        /**
         *
         */
        service.startFormTracking = function () {
            service.formTrackingData = [];
        };

        /**
         *
         * @param field
         */
        service.trackFormInteraction = function (field) {
            service.formTrackingData.push($filter('analyticsFormFieldFilter')(field));

            $log.debug('analyticsService.trackFormInteraction', service.formTrackingData);
        };

        /**
         * Track the last form interaction and route
         */
        service.finishFormTracking = function () {

            trackAnalyticsEvent({
                linkTrackVars: 'eVar30,events',
                linkTrackEvents: 'event17',
                eVar30: service.formTrackingData[service.formTrackingData.length - 1],
                events: 'event17'
            }, 'Last Field');
        };

        /**
         * Track server errors
         * @param {String} error Error
         */
        service.trackServerError = function (error) {
            $log.error('analyticsService.trackServerError:', error);
            trackAnalyticsEvent(angular.extend({}, defaults.BASE_TRACKING_SERVER_ERRORS, {
                eVar26: error || 'Unknown Error'
            }), 'Server Side Error');
        };

        /**
         * Update the view
         * @param {String} section Current application view/state
         */
        service.updateView = function (section) {

            if (!section) {
                return;
            }

            service.currentSection = section;

            $log.debug('analyticsService - Update view:', section);

            configureViewData(section);
            tealium.view(section);

            $log.info('analyticsService - Tealium udo:', tealiumData.getUdo(section));
        };

        /**
         * Track errors in a form
         * @param {Object} form Form Object
         */
        service.trackFormErrors = function (form) {
            service.trackFormErrorList($filter('formErrorsFilter')(form));
        };

        /**
         *
         * @param {String} errorList List of errors as String
         */
        service.trackFormErrorList = function (errorList) {
            trackAnalyticsEvent(angular.extend({}, defaults.BASE_TRACKING_FORM_ERRORS, {
                eVar35: errorList,
                list1: errorList
            }), 'Form Error');
        };

        /**
         * Track (a default) event
         * @param {String} event Event String to be passed to Adobe Analytics via window.s
         * @param {String} item name of item currently being tracked
         */
        service.trackEvent = function (event, item) {
            trackAnalyticsEvent(angular.extend({}, defaults.BASE_TRACKING_EVENT, {
                prop27: event,
                eVar31: event
            }), item);
        };

        /**
         * Track a custom event
         * @param {Object} event A complete event Object to be passed to Adobe Analytics via window.s
         * @param {String} item name of item currently being tracked
         */
        service.trackCustomEvent = function (event, item) {
            trackAnalyticsEvent(event, item);
        };

        /**
         * Add analytics data
         * @param {Object} data Manual data to be added
         */
        service.addAnalyticsData = function(data) {
            $log.info('We are adding:', data);
            service.manualData = angular.extend({}, service.manualData, data);
        };

        /**
         *
         * @param route
         */
        service.addRoute = function (route) {
            service.addAnalyticsData({
                'adoURL': $location.absUrl().substr(0, $location.absUrl().lastIndexOf('/') + 1) + route
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('analyticsService', analyticsService);

})(window.angular, window);