(function(angular) {

    'use strict';

    /**
     * @ngdoc directive
     * @name ntb-fe.directive:dateField
     * @description
     * Reformats inputted date to DD/MM/YYYY as a String
     *
     * @restrict E
     *
     * @ngInject
     *
     * @returns {Object} Directive
     */
    function expiryDateField() {

        function checkExpiry(value) {

            if (!value) {
                return;
            }

            value = value.split('/');

            var now = new Date(),
                monthNum = parseInt(value[0], 10),
                yearNum = (Math.floor(now.getFullYear() /100) * 100) + parseInt(value[1], 10);

            return monthNum <= 12 && (yearNum > now.getFullYear()) || (yearNum === now.getFullYear() && monthNum >= (now.getMonth() + 1));
        }

        function link(scope, element, attrs, ngModel) {

            function updateExpiry(scope, event) {

                // Remove user-input formatting for expiry date
                var inputExpiryDate = element.val().replace(/[\/-]/g, '').split('');

                if (inputExpiryDate[2]) {
                    inputExpiryDate.splice(2, 0, '/');
                }

                // Numerical KeyCodes only for expiry date
                if (event && event.keyCode && ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
                    element.val(inputExpiryDate.join(''));
                    ngModel.$setViewValue(inputExpiryDate.join(''));
                }
            }

            element.on('blur change keyup', function(event) {
                scope.$evalAsync(updateExpiry, event);
            });
            updateExpiry();

            ngModel.$parsers.unshift(function(value) {
                var valid = checkExpiry(value);
                ngModel.$setValidity('expired', valid);
                return valid ? value : undefined;
            });

            ngModel.$formatters.unshift(function(value) {
                ngModel.$setValidity('expired', checkExpiry(value));
                return value;
            });
        }

        return {
            restrict   : 'A',
            require    : '^ngModel',
            link       : link
        };
    }

    angular
        .module('ntb-fe')
        .directive('expiryDateField', expiryDateField);

})(window.angular);
