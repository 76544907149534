(function(angular) {

    'use strict';

    // Default (Konami) keycode
    var activeEgg = '38,38,40,40,37,39,37,39,98,97',
        ignoredKeys = [16],
        kps = [],
        body;

    /* jshint maxcomplexity: 13 */
    function handleKeyboardEvent(e) {

        var keyCode = e.which,
            isLetter = keyCode >= 65 && keyCode <= 90;

        if (e.type === 'keydown' && !e.metaKey && !e.ctrlKey && !e.altKey && !e.shiftKey) {
            if (keyCode === 9) {
                body.removeClass('no-outline');
            }
        }

        if (e.type === 'keyup') {

            if (isLetter && !e.shiftKey) {
                keyCode = keyCode + 32;
            }

            if (ignoredKeys.indexOf(keyCode) === -1) {
                kps.push(keyCode);
            }

            if (kps.toString().indexOf(activeEgg) >= 0) {
                kps = [];
                body.addClass('greyscale');
                return;
            }

            if (kps.length > 8) {

                var currentKeys = kps.toString(),
                    firstEggKey = activeEgg.split(',')[0];

                if (!firstEggKey || currentKeys.indexOf(firstEggKey) === -1) {
                    kps = [];
                    return;
                }

                currentKeys = currentKeys.substr(currentKeys.indexOf(firstEggKey));
                if (currentKeys.length > activeEgg.length) {
                    kps = [];
                }
            }
        }
    }

    /**
     * @ngdoc directive
     * @name ntb-fe.component:watchAccessibility
     * @description
     * **Component**
     *
     * Watches for keyboard or mouse input and sets class on BODY.
     *
     * @restrict E
     *
     * @ngInject
     */
    function controller($document) {
        body = angular.element($document[0].body);

        function handleMouseEvent() {
            body.addClass('no-outline');
        }

        $document.on('keydown', handleKeyboardEvent);
        $document.on('keyup', handleKeyboardEvent);
        $document.on('mousedown', handleMouseEvent);
    }

    angular
        .module('ntb-fe')
        .component('watchAccessibility', {
            controller: controller
        });

})(window.angular);