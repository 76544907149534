(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:momentService
     * @requires ntb-fe.service:contactService
     * @requires ntb-fe.service:momentService
     * @requires ntb-fe.service:dataModelService
     * @requires ntb-fe.constant:defaults
     *
     * @name ntb-fe.controller:ContactDetailsController
     * @description
     * Contact details controller
     *
     * @example
     * *ContactDetailsController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'ContactDetailsController as contact'
     * </pre>
     *data
     * @ngInject
     */
    function ContactDetailsController($log, $rootScope, $state, momentService, contactService, dataModelService, analyticsService, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ContactDetailsController
         * @name ntb-fe.controller:ContactDetailsController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ContactDetailsController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError: false,
            serverError: false,
            hasDateError: false,
            isSubmitting: false,
            isJointApplication: dataModelService.isJointApplication(),
            primary: applicantNotifications('primary'),
            secondary: dataModelService.isJointApplication() ? applicantNotifications('secondary') : void 0
        };

        /**
         *
         * @param {Object} item Form item
         */
        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @private
         * @returns {Object}
         */
        function applicantNotifications(applicant) {
            return {
                firstName: dataModelService.getFirstName(applicant),
                prevAddressRequired: false,
                currentAddress: {
                    postcodeValid: void 0,
                    loadingPostcode: false,
                    addressSelected: false,
                    addressResults: void 0,
                    futureDate: false,
                    bfpoAddress: false,
                    addressDateBeforeDob: false,
                    serverWait: false,
                    manualEntry: false,
                    hasAddressError: false
                },
                previousAddress: {
                    postcodeValid: void 0,
                    loadingPostcode: false,
                    addressSelected: false,
                    addressResults: void 0,
                    addressDateBeforeCurrent: false,
                    serverWait: false,
                    manualEntry: false,
                    hasAddressError: false
                }
            };
        }

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:ContactDetailsController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('contactDetails');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('contactDetails');

        /**
         * @private
         * @param {String} prospect Prospect primary|secondary
         * @param {String} addressType Address Type currentAddress|previousAddress
         */
        function clearAddress(prospect, addressType) {

            // Rebuild broken or null Objects
            vm.userData[prospect][addressType] = vm.userData[prospect][addressType] || {};
            vm.userData.interactions[prospect][addressType] = vm.userData.interactions[prospect][addressType] || {};
            vm.notifications[prospect][addressType] = vm.notifications[prospect][addressType] || {};

            // Populate
            vm.userData[prospect][addressType].yearsAtAddress = void 0;
            vm.userData[prospect][addressType].monthsAtAddress = void 0;
            vm.userData[prospect][addressType].address = void 0;

            vm.userData.interactions[prospect][addressType].addressStartMonth = void 0;
            vm.userData.interactions[prospect][addressType].addressStartYear = void 0;
            vm.userData.interactions[prospect][addressType].addressSelected = false;
            vm.userData.interactions[prospect][addressType].manualEntry = false;

            vm.notifications[prospect][addressType].addressResults = void 0;
            vm.notifications[prospect][addressType].loadingPostcode = false;

            if (addressType === 'currentAddress') {

                // Reconstruct
                if (!vm.userData.interactions[prospect].previousAddress) {
                    vm.userData.interactions[prospect].previousAddress = {};
                }

                vm.userData[prospect].residentialStatus = void 0;
                vm.notifications[prospect].prevAddressRequired = false;
                vm.userData.interactions[prospect].previousAddress.postcode = void 0;

                clearAddress(prospect, 'previousAddress');
            }
        }

        /**
         *
         * @param isValid
         * @param prospect
         * @param addressType
         */
        vm.findAddress = function (isValid, prospect, addressType) {

            vm.notifications[prospect][addressType].postcodeValid = isValid;
            vm.notifications[prospect][addressType].hasAddressError = false;

            vm.notifications.hasFormError = !isValid;

            if (vm.notifications.hasFormError) {
                if (vm.notifications.hasFormError) {
                    analyticsService.trackFormErrorList(prospect + '_' + addressType + '_tmp_postcode');
                }
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications[prospect][addressType].loadingPostcode) {
                return;
            }

            // Reconstruct
            if (!vm.userData[prospect][addressType]) {
                vm.userData[prospect][addressType] = {};
            }

            clearAddress(prospect, addressType);

            // Check for BFPO Address
            vm.checkPostcode(prospect, addressType);

            if (vm.notifications[prospect][addressType].bfpoAddress) {
                return;
            }

            vm.notifications[prospect][addressType].loadingPostcode = true;
            vm.notifications[prospect][addressType].serverWait = false;

            function success(response) {
                vm.userData.interactions[prospect][addressType].manualEntry = !response.premisesNames.length;
                vm.notifications[prospect][addressType].addressResults = response;
                vm.notifications[prospect][addressType].loadingPostcode = false;
            }

            function error(error) {

                analyticsService.trackServerError('postcodeLookup: ' + error.status);

                vm.notifications[prospect][addressType].loadingPostcode = false;

                if (error.status && error.status === 429) {
                    vm.notifications[prospect][addressType].serverWait = true;
                    return;
                }

                vm.notifications[prospect][addressType].serverWait = false;
                vm.userData.interactions[prospect][addressType].manualEntry = true;
            }

            contactService.postcodeLookup(vm.userData.interactions[prospect][addressType].postcode.replace(/\s+/g, '')).then(success, error);
            analyticsService.trackEvent('Prod>CA - ' + prospect + ' ' + addressType + ' Find', 'Find Address');
        };

        /**
         *
         * @param prospect
         * @param addressType
         * @param manual
         */
        vm.checkPostcode = function(prospect, addressType, manual) {
            if (addressType === 'currentAddress' && prospect === 'primary' && vm.notifications[prospect] && vm.notifications[prospect][addressType]) {
                if (manual) {
                    vm.notifications[prospect][addressType].bfpoAddress = vm.userData[prospect][addressType].address.postcode.toUpperCase().indexOf('BF') !== -1;
                } else {
                    vm.notifications[prospect][addressType].bfpoAddress = vm.userData.interactions[prospect][addressType].postcode.toUpperCase().indexOf('BF') !== -1;
                }
            }

            if (vm.notifications[prospect][addressType].bfpoAddress && !manual) {
                vm.manualEntry(prospect, addressType);
            }
        };

        /**
         *
         * @param prospect
         * @param addressType
         * @param name
         */
        vm.selectAddress = function (prospect, addressType, name) {

            vm.notifications[prospect][addressType].hasAddressError = false;

            vm.userData[prospect][addressType].address = void 0;
            vm.notifications[prospect][addressType].addressResults = void 0;
            vm.userData.interactions[prospect][addressType].manualEntry = false;

            /**
             * Determine if the response for the address is valid.
             *
             * @param  {Object} response
             *
             * @return {Boolean}
             */
            function addressResponseIsValid(response) {
                return response.address1 !== '' && response.address2 !== '' && response.postcode !== '';
            }

            function success(response) {
                vm.userData[prospect][addressType].address = response;

                var isValid = addressResponseIsValid(response);

                // update props based on validity of response
                vm.userData.interactions[prospect][addressType].addressSelected = isValid;
                vm.userData.interactions[prospect][addressType].manualEntry = ! isValid;
            }

            function error(error) {
                vm.userData.interactions[prospect][addressType].addressSelected = false;
                analyticsService.trackServerError('selectAddress: ' + error.status);
            }

            contactService.getAddress(vm.userData.interactions[prospect][addressType].postcode.replace(/\s+/g, '').toUpperCase(), name).then(success, error);
        };

        /**
         *
         * @param prospect
         * @param addressType
         */
        vm.manualEntry = function (prospect, addressType) {
            vm.notifications[prospect][addressType].hasAddressError = false;

            clearAddress(prospect, addressType);
            vm.userData.interactions[prospect][addressType].manualEntry = true;

            analyticsService.trackEvent('Prod>CA - ' + prospect + ' ' + addressType + ' Manual', 'Manual Address');
        };

        /**
         * @private
         */
        function isPrevAddressRequired(applicant) {

            vm.notifications[applicant].prevAddressRequired = false;

            if (vm.userData[applicant].currentAddress.yearsAtAddress) {
                vm.notifications[applicant].prevAddressRequired = vm.userData[applicant].currentAddress.yearsAtAddress < defaults.PREV_ADDRESS_REQUIRED_YEARS;
            } else if (vm.userData[applicant].currentAddress.monthsAtAddress || vm.userData[applicant].currentAddress.daysAtAddress) {
                vm.notifications[applicant].prevAddressRequired = true;
            }
        }

        /**
         * @private
         */
        function calculateTimeAtAddress(prospect, addressType) {

            /* jshint maxcomplexity: 9 */

            var startMonth = vm.userData.interactions[prospect][addressType].addressStartMonth,
                startYear = vm.userData.interactions[prospect][addressType].addressStartYear,
                timeAtAddress;

            // Reset time at address
            vm.userData[prospect][addressType].yearsAtAddress = void 0;
            vm.userData[prospect][addressType].monthsAtAddress = void 0;
            vm.userData[prospect][addressType].daysAtAddress = void 0;

            if (addressType === 'currentAddress') {
                timeAtAddress = momentService.calculateAddressTime(startMonth, startYear);
            } else {
                timeAtAddress = momentService.calculatePreviousAddressTime(vm.userData.interactions[prospect].currentAddress.addressStartMonth, vm.userData.interactions[prospect].currentAddress.addressStartYear, startMonth, startYear);
            }

            vm.notifications.hasDateError = !timeAtAddress || ((!timeAtAddress.years || timeAtAddress.years < 0) && (!timeAtAddress.months || timeAtAddress.months < 0) && (!timeAtAddress.days || timeAtAddress.days < 0));

            if (addressType !== 'currentAddress') {
                vm.notifications[prospect][addressType].addressDateBeforeCurrent = vm.notifications.hasDateError;
            } else {
                vm.notifications[prospect][addressType].futureDate = vm.notifications.hasDateError;
            }

            if (vm.notifications.hasDateError) {
                return;
            }

            vm.notifications[prospect][addressType].futureDate = false;
            vm.notifications[prospect][addressType].addressDateBeforeDob = false;

            if (momentService.isMonthBeforeDob(dataModelService.getDateOfBirth(prospect), startMonth, startYear)) {
                vm.notifications.hasDateError = true;
                vm.notifications[prospect][addressType].addressDateBeforeDob = true;
            }

            return timeAtAddress;
        }

        /**
         *
         * @param prospect
         * @param addressType
         */
        vm.checkTimeAtAddress = function (prospect, addressType) {

            var startMonth = vm.userData.interactions[prospect][addressType].addressStartMonth,
                startYear = vm.userData.interactions[prospect][addressType].addressStartYear;

            if (!startMonth || !startYear) {
                return;
            }

            if (!vm.userData[prospect][addressType]) {
                vm.userData[prospect][addressType] = {};
            }

            var timeAtAddress = calculateTimeAtAddress(prospect, addressType);

            vm.userData[prospect][addressType].yearsAtAddress = void 0;
            vm.userData[prospect][addressType].monthsAtAddress = void 0;
            vm.userData[prospect][addressType].daysAtAddress = void 0;

            if (timeAtAddress) {
                vm.userData[prospect][addressType].yearsAtAddress = timeAtAddress.years;
                vm.userData[prospect][addressType].monthsAtAddress = timeAtAddress.months;
                vm.userData[prospect][addressType].daysAtAddress = timeAtAddress.days;
            }

            if (addressType === 'currentAddress') {

                isPrevAddressRequired(prospect);

                if (vm.notifications[prospect].prevAddressRequired) {
                    vm.checkTimeAtAddress(prospect, 'previousAddress');
                    return;
                }

                vm.userData[prospect].previousAddress = void 0;
                vm.userData.interactions[prospect].previousAddress.addressSelected = false;
                vm.userData.interactions[prospect].previousAddress.addressStartMonth = void 0;
                vm.userData.interactions[prospect].previousAddress.addressStartYear = void 0;
                vm.userData.interactions[prospect].previousAddress.postcode = void 0;
            }
        };

        /**
         * @private
         * @ngdoc method
         * @methodOf ntb-fe.controller:ContactDetailsController
         * @name checkAddressStatus
         * @description
         * Ensures that the user has completed all address fields by forcing manual entry flag on incomplete address entries.
         *
         * @returns {Boolean} Valid
         * @type {Function}
         */
        function checkAddressStatus() {

            var isValid = true;

            function checkProspectAddress(prospect) {

                if (!vm.userData.interactions[prospect].currentAddress.addressSelected && !vm.userData.interactions[prospect].currentAddress.manualEntry) {
                    vm.notifications[prospect].currentAddress.hasAddressError = true;
                    isValid = false;
                }

                if (vm.notifications[prospect].prevAddressRequired && !vm.userData.interactions[prospect].previousAddress.addressSelected && !vm.userData.interactions[prospect].previousAddress.manualEntry) {
                    vm.notifications[prospect].previousAddress.hasAddressError = true;
                    isValid = false;
                }
            }

            // Check primary prospect
            checkProspectAddress('primary');

            // Check secondary prospect
            if (vm.notifications.isJointApplication) {
                checkProspectAddress('secondary');
            }

            return isValid;
        }

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:ContactDetailsController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="contactForm" data-ng-submit="contact.submitUserData(contactForm.$valid)">
         *         ...
         *     </form>
         * </pre>$filter
         *
         * @type {Function}
         */
        vm.submitUserData = function (form) {

            var invalidAddress = !checkAddressStatus();

            vm.notifications.hasFormError = !form.$valid || invalidAddress;

            if (vm.notifications.hasFormError || vm.notifications.hasDateError) {

                if (vm.notifications.hasFormError) {
                    analyticsService.trackFormErrors(form);
                }

                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // Catch address finder ENTER key to prevent server error.
            if (!vm.userData.primary.mobileNumber && !vm.userData.primary.phoneNumber) {
                return;
            }

            dataModelService.updateModel('contactDetails', vm.userData);
            analyticsService.addRoute('personal-details');
            analyticsService.finishFormTracking();

            vm.notifications.serverError = false;
            vm.notifications.isSubmitting = true;

            contactService.submitContactDetails(vm.userData)
                .then(function () {
                    $state.go('personalDetails');
                }, function (error) {
                    $log.error(error);
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitContactDetails: ' + error.status);
                });
        };

        // IIFE init
        (function () {

            analyticsService.startFormTracking();

            isPrevAddressRequired('primary');

            if (vm.notifications.isJointApplication) {
                isPrevAddressRequired('secondary');
            }

        })();
    }

    angular
        .module('ntb-fe')
        .controller('ContactDetailsController', ContactDetailsController);

})(window.angular);
