(function(angular) {

    'use strict';

    /**
     *
     * @param dataModelService
     * @returns {Function}
     *
     * @ngInject
     */
    function analyticsFormFieldFilter(dataModelService) {
        return function(field) {

            var isJointApplication = dataModelService.isJointApplication(),
                parsed,
                parsedName;

            if (angular.isString(field)) {
                parsed = field.split('_');
                parsedName = field;
            } else {
                parsed = field.$name.split('_');
                parsedName = field.$name;
            }

            if (parsed.length > 1 && parsed[0] === 'primary') {
                parsed.shift();
                parsedName = (isJointApplication ? 'p_' : '') + parsed.join('_');
            }
            if (parsed.length > 1 && parsed[0] === 'secondary') {
                parsed.shift();
                parsedName = (isJointApplication ? 's_' : '') + parsed.join('_');
            }

            return parsedName;
        };
    }

    angular
        .module('ntb-fe')
        .filter('analyticsFormFieldFilter', analyticsFormFieldFilter);

})(window.angular);