(function(angular) {

    'use strict';

    /**
     * @ngdoc directive
     * @name ntb-fe.directive:debitCardValidation
     * @description
     * Validates a debit card number (PAN) against the Luhn algorithm
     *
     * @restrict A
     *
     * @ngInject
     *
     * @returns {Object} Directive
     */
    function debitCardValidation() {

        // Luhn algorithm check
        var luhnChk = (function (arr) {
            return function (ccNum) {

                if (!ccNum) {
                    return;
                }

                ccNum = ccNum.replace(/\s/g, '');

                var len = ccNum.length,
                    bit = 1,
                    sum = 0,
                    val;

                while (len) {
                    val = parseInt(ccNum.charAt(--len), 10);
                    /* jshint bitwise: false */
                    sum += (bit ^= 1) ? arr[val] : val;
                    /* jshint bitwise: true */
                }

                return Boolean(ccNum.length >= 12 && sum && sum % 10 === 0);
            };
        }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

        function link(scope, element, attr, ngModel) {

            ngModel.$parsers.unshift(function(value) {
                var valid = luhnChk(value);
                ngModel.$setValidity('luhn', valid);
                return valid ? value : undefined;
            });

            ngModel.$formatters.unshift(function(value) {
                ngModel.$setValidity('luhn', luhnChk(value));
                return value;
            });

            function update(scope, event) {

                // Remove user-input formatting
                var inputPan = element.val().replace(/[\/-]/g, '').split('');

                if (inputPan[4] && inputPan[4] !== ' ') {
                    inputPan.splice(4, 0, ' ');
                }
                if (inputPan[9] && inputPan[9] !== ' ') {
                    inputPan.splice(9, 0, ' ');
                }
                if (inputPan[14] && inputPan[14] !== ' ') {
                    inputPan.splice(14, 0, ' ');
                }

                // Numerical KeyCodes only for PAN
                if (event && event.keyCode) {
                    element.val(inputPan.join(''));
                    ngModel.$setViewValue(inputPan.join(''));
                }
            }

            element.on('blur change keyup', function(event) {
                // Update after digest
                scope.$evalAsync(update, event);
            });
            update();
        }
        
        return {
            restrict   : 'A',
            require    : '^ngModel',
            link       : link
        };
    }

    angular
        .module('ntb-fe')
        .directive('debitCardValidation', debitCardValidation);

})(window.angular);