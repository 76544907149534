(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:registrationService
     * @description
     *
     * User registration service
     *
     * @requires ntb-fe.factory:registration
     * @requires ntb-fe.filter:registrationData
     * @requires ntb-fe.service:momentService
     * @requires ntb-fe.constant:defaults
     *
     * @example
     *
     * <pre>
     *     function SomeController(registrationService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function registrationService($q, $filter, momentService, registration, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:registrationService
         * @name ntb-fe.service:registrationService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:registrationService
         * @name service.submitregistration
         * @description
         * Submit the userData Object via the registration factory and handle the response
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.submitRegistration = function(userData) {

            return $q(function(resolve, reject) {
                registration.save(userData, function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:registrationService
         * @name service.calcAge
         * @description
         * Calculate the applicant age based on DOB
         *
         * @type {Object}
         */
        service.calcAge = function(dob) {

            var filtered = $filter('dateOfBirth')(dob);

            if (filtered.length === 10) {
                var calculatedAge = momentService.calculateAge(filtered);
                return !calculatedAge ? 'ERROR' : calculatedAge < defaults.APPLICANT_MIN_AGE ? 'UNDER' : calculatedAge > defaults.APPLICANT_MAX_AGE ? 'OVER' : false;
            }

            return false;
        };
    }

    angular
        .module('ntb-fe')
        .service('registrationService', registrationService);

})(window.angular);