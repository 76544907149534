(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:switchingService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:SwitchingController
     * @description
     * Contact details controller
     *
     * @example
     * *SwitchingController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'SwitchingController as contact'
     * </pre>
     *
     * @ngInject
     */
    function SwitchingController($log, $rootScope, $state, $q, switchingService, dataModelService, analyticsService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:SwitchingController
         * @name ntb-fe.controller:SwitchingController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:SwitchingController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="switching.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError: false,
            serverError: false,
            validateError: false,
            serverWait: false,
            isSubmitting: false,
            isValidating: false,
            isLoadingDates: false,
            switchingAllowed: void 0, // Boolean
            switchingEligibility: void 0, // String
            availableSwitchDates: {},
            userDetails: dataModelService.getUserDetails('primary'),
            isJointApplication: dataModelService.isJointApplication(),
			isoverDraft       : dataModelService.hasRequestedOverdraft(),
            switchDate: {
                full: void 0, // String
                partial: void 0 // String
            },
            secondary: {
                firstName: dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : ''
            }
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:SwitchingController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('switching');

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.trackFormElement
         * @description
         * Track form element
         *
         * @type {Function}
         */
        vm.trackFormElement = function (item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.useExistingDetails
         * @description
         *
         *
         * @type {Function}
         */
        vm.useExistingDetails = function () {
            vm.userData.accountToSwitch = {
                accountNumber: vm.notifications.userDetails.accountNumber,
                sortcode: vm.notifications.userDetails.sortcode
            };

            vm.validateAccount();
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.manualEntry
         * @description
         *
         *
         * @type {Function}
         */
        vm.manualEntry = function () {

            vm.notifications.switchingAllowed = void 0;
            vm.notifications.switchingEligibility = void 0;

            $log.debug('MANUAL: ', vm.notifications);

            vm.userData.accountToSwitch = {
                accountNumber: vm.notifications.userDetails.accountNumber,
                sortcode: vm.notifications.userDetails.sortcode
            };
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.resetExistingAccount
         * @description
         * Reset the existing account type
         *
         * @type {Function}
         */
        vm.resetExistingAccount = function (type, value) {
            switch (type) {
                case 'joint':
                    vm.userData.accountToSwitch.jointAccount = value;
                    vm.userData.accountToSwitch.primaryApplicantAccount = void 0;
                    vm.userData.accountToSwitch.secondaryApplicantAccount = void 0;
                    break;
                case 'primary':
                    vm.userData.accountToSwitch.primaryApplicantAccount = value;
                    vm.userData.accountToSwitch.secondaryApplicantAccount = void 0;
                    break;
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.resetForm
         * @description
         * Reset the form data with option to keep switch type
         *
         * @type {Function}
         */
        vm.resetForm = function (keepType, value) {
            var tmpData = dataModelService.getBlankModelData('switching'),
                isSwitching = vm.userData.interactions.switch;
            vm.userData = angular.copy(tmpData);
            vm.userData.interactions = angular.copy(tmpData.interactions);
            vm.userData.switching = false;
            vm.notifications.switchingAllowed = void 0;
            vm.notifications.switchingEligibility = void 0;
            vm.notifications.validateError = false;
            vm.userData.accountToSwitch.jointAccount = void 0;
            vm.userData.accountToSwitch.primaryApplicantAccount = void 0;
            vm.userData.accountToSwitch.secondaryApplicantAccount = void 0;

            if (keepType) {
                vm.userData.interactions.switch = isSwitching;
                vm.userData.switching = true;
                vm.userData.fullSwitch = value;

                // Set transfer date to first partial switch date
                if (value === false) {
                    vm.userData.transferDate = vm.notifications.switchDate.partial;
                }
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.resetDebitCard
         * @description
         * Reset the debit card data
         *
         * @type {Function}
         */
        vm.resetDebitCard = function (applicant) {
            if (!vm.userData.debitCard) {
                vm.userData.debitCard = {};
            }
            vm.userData.debitCard[applicant] = {
                pan: void 0,
                expiry: void 0
            };
        };

        /**
         * @private
         * @param eligibility
         */
        function checkSwitchEligibility(eligibility) {

            $log.debug('VALIDATE ELIGIBILITY:', eligibility);

            vm.notifications.switchingAllowed = angular.isDefined(eligibility) && eligibility !== 'N';
            vm.notifications.switchingEligibility = eligibility;
        }

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.validateAccount
         * @description
         * Passes the user's current bank details to the service in order to validate if the account is eligible to switch and handles the response
         *
         * @type {Function}
         */
        vm.validateAccount = function () {

            vm.notifications.isValidating = true;
            vm.notifications.serverWait = false;
            vm.notifications.validateError = false;

            return $q(function (resolve, reject) {
                switchingService.validateAccount(vm.userData.accountToSwitch).then(function (response) {

                    checkSwitchEligibility(response.switchingEligibility);
                    vm.notifications.isValidating = false;
                    resolve(response);

                }, function (error) {

                    vm.notifications.isValidating = false;

                    if (error.status && error.status === 429) {
                        vm.notifications.serverWait = true;
                    } else {
                        vm.notifications.validateError = true;
                    }

                    analyticsService.trackServerError('validateAccount: ' + error.status);
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.skipSwitching
         * @description
         * Skip switching
         *
         * @type {Function}
         */
        vm.skipSwitching = function () {
            vm.resetForm();
            vm.userData.fullSwitch = void 0;
            vm.userData.partialAllPayments = void 0;
            vm.userData.partialSomePayments = void 0;
            vm.userData.interactions.switch = false;
            vm.submitUserData({$valid: true});
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.resetPartial
         * @description
         * Reset partial payments option
         *
         * @type {Function}
         */
        vm.resetPartial = function () {
            vm.userData.partialSomePayments = void 0;
            vm.userData.interactions.confirmAccount = void 0;
            vm.notifications.switchingAllowed = void 0;
            vm.notifications.switchingEligibility = void 0;
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.acceptSwitchDate
         * @description
         * Accept the switch date
         *
         * @type {Function}
         */
        vm.acceptSwitchDate = function (type) {
            vm.userData.transferDate = vm.notifications.switchDate[type];
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:SwitchingController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="contactForm" data-ng-submit="switching.submitUserData(switchingForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function (form) {

            vm.notifications.hasFormError = !form.$valid;

            // Switching
            if (vm.notifications.hasFormError) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // Switching - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;

            // Remove secondary debit card if not joint application
            if (!vm.notifications.isJointApplication && vm.userData.debitCard && vm.userData.debitCard.secondary) {
                delete vm.userData.debitCard.secondary;
            }

            dataModelService.updateModel('switching', vm.userData);
            analyticsService.addRoute('memorable-word');
            analyticsService.finishFormTracking();

            if (angular.isDefined(vm.userData.accountToSwitch.sortcode)) {

                // Re-validate the account details to catch if they have been modified
                vm.validateAccount().then(function (response) {

                    if (response.switchingEligibility === 'N') {
                        vm.notifications.isSubmitting = false;
                        return;
                    }

                    submitUserData();
                }, function () {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                });

            } else {
                submitUserData();
            }
        };

        /**
         * @private
         */
        function submitUserData() {
            switchingService.submitSwitchingDetails(vm.userData)
                .then(function () {
                    $state.go('memorableWord');
                }, function (error) {
                    $log.error('SwitchingController - submitSwitchingDetails:', error);
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitSwitchingDetails: ' + error.status);
                });
        }

        // IIFE init
        (function () {

            analyticsService.startFormTracking();

            // Recheck the validation on return/resume
            if (angular.isDefined(vm.userData.interactions.confirmAccount)) {
                vm.validateAccount();
            }

            function getSwitchingDates(type) {

                vm.notifications.isLoadingDates = true;
                vm.notifications.availableSwitchDates = {};

                switchingService.getSwitchingDates(type)
                    .then(function (response) {

                        vm.notifications.availableSwitchDates[type] = response;
                        vm.notifications.isLoadingDates = false;
                        vm.notifications.serverError = false;

                        if (response.length) {
                            vm.notifications.switchDate[type] = response[0];
                        }

                    }, function (error) {

                        $log.error('getSwitchingDates:', error);

                        vm.notifications.availableSwitchDates[type] = [];
                        vm.notifications.isLoadingDates = false;
                        vm.notifications.serverError = true;
                        analyticsService.trackServerError('getSwitchingDates: ' + error.status);
                    });
            }

            getSwitchingDates('full');
            getSwitchingDates('partial');

        })();
    }

    angular
        .module('ntb-fe')
        .controller('SwitchingController', SwitchingController);

})(window.angular);
