(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:incomeService
     * @requires ntb-fe.service:dataModelService
     * @requires ntb-fe.service:intervalAmountService
     *
     * @name ntb-fe.controller:IncomeController
     * @description
     * Contact details controller
     *
     * @example
     * *IncomeController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'IncomeController as contact'
     * </pre>
     *
     * @ngInject
     */
    function IncomeController($rootScope, $state, incomeService, overdraftService, dataModelService, intervalAmountService, analyticsService, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:IncomeController
         * @name ntb-fe.controller:IncomeController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:IncomeController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError: false,
            serverError : false,
            isSubmitting: false,
            isJointApplication: dataModelService.isJointApplication(),
            prospectsEligibleForOverdraft : allProspectsEligible(),
            totalIncome: 0,
            primary: {
                firstName: dataModelService.getFirstName('primary'),
                showRetirementAge: showRetirementAgeForProspect('primary'),
                invalidRetirement: false,
                invalidIncomeAfterTax: false,
                invalidIncomeNonZero: false
            },
            secondary: {
                firstName: dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : '',
                showRetirementAge: dataModelService.isJointApplication() ? showRetirementAgeForProspect('secondary') : void 0,
                invalidRetirement: false,
                invalidIncomeAfterTax: false,
                invalidIncomeNonZero: false
            }
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:IncomeController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('income');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('income');

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:IncomeController
         * @name vm.userDataSwitiching
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('switching');
         * </pre>
         *
         * @type {Object}
         */
		 vm.userDataSwitching = dataModelService.getModelData('switching');
		
        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         * @private
         */
        function clearRetirementAge() {
            if (!vm.notifications.primary.showRetirementAge) {
                vm.userData.primary.retirementAge = void 0;
            }

            if (vm.userData.secondary && !vm.notifications.secondary.showRetirementAge) {
                vm.userData.secondary.retirementAge = void 0;
            }
        }

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:IncomeController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="employmentForm" data-ng-submit="employment.submitUserData(employmentForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {
            //logic for overdraft true/false
            if(angular.isUndefined(vm.userData.overdraftPreviousValue)){ 
               if(vm.userData.overdraft){                   
                   vm.userDataSwitching.interactions.switch = false;                  
               }
            } else
            { 
                if(vm.userData.overdraft){
                  vm.userDataSwitching.interactions.switch = false;
                } else { 
                    if(vm.userData.overdraftPreviousValue){
                        vm.userDataSwitching.interactions.switch = void 0;
                    }
                }
             }
            
            vm.userData.overdraftPreviousValue = vm.userData.overdraft;

            function notificationsOr(name) {
                return vm.notifications.primary[name] || vm.notifications.secondary[name];
            }
            vm.notifications.hasFormError = !form.$valid;

            // Income
            if (vm.notifications.hasFormError) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            var invalidRetirement = vm.userData.overdraft && notificationsOr('invalidRetirement'),
                invalidIncome = notificationsOr('invalidIncomeAfterTax') || notificationsOr('invalidIncomeNonZero');

            if (invalidRetirement || invalidIncome) {
                return;
            }

            // Track overdraft application
            if (angular.isDefined(vm.userData.overdraft)) {
                analyticsService.trackEvent('NTB - Overdraft ' + vm.userData.overdraft, 'NTB - Overdraft');
            }

            clearRetirementAge();

            // Income & outgoings - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;
			

            dataModelService.updateModel('income', vm.userData);
            analyticsService.addRoute(vm.userData.overdraft ? 'overdraft' : 'bankDetails');
            analyticsService.finishFormTracking();

            incomeService.submitIncomeDetails(vm.userData)
                .then(function() {

                    // Ensure that no overdraft data is retained if the user returns to this screen and selects "No"...
                    if (!vm.userData.overdraft) {

                        var tmpData = dataModelService.getBlankModelData('overdraft');
                        tmpData.overdraftAmount = 0;
                        dataModelService.updateModel('overdraft', tmpData);
                        overdraftService.submitOverdraftDetails(tmpData)
                            .then(function() {
                                $state.go(vm.userData.overdraft ? 'overdraft' : 'bankDetails');
                            }, function() {
                                vm.notifications.isSubmitting = false;
                                vm.notifications.serverError = true;
                            });

                    } else {
                        $state.go(vm.userData.overdraft ? 'overdraft' : 'bankDetails');
                    }

                }, function(error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitIncomeDetails: ' + error.status);
                });
        };

        /**
         *
         * @param applicant
         */
        vm.checkRetirementAge = function(applicant) {
            var age = dataModelService.getApplicantAge(applicant),
                retirementAge = parseInt(vm.userData[applicant].retirementAge, 10);

            vm.notifications[applicant].invalidRetirement = retirementAge < age;
        };

        /**
         *
         * @param data
         */
        function checkIncomeValues(data) {

            function checkValues(applicant) {

                if (angular.isUndefined(data.interactions[applicant].incomeBeforeTax) || angular.isUndefined(data.interactions[applicant].incomeAfterTax)) {
                    vm.notifications[applicant].invalidIncomeAfterTax = false;
                    vm.notifications[applicant].invalidIncomeNonZero = false;
                    return;
                }

                var annualIncomeBeforeTax = intervalAmountService.calculateYearlyValue(data.interactions[applicant].incomeBeforeTax, data.interactions[applicant].incomeBeforeTaxInterval),
                    monthlyIncomeAfterTax = intervalAmountService.calculateMonthlyValue(data.interactions[applicant].incomeAfterTax, data.interactions[applicant].incomeAfterTaxInterval);

                vm.notifications[applicant].invalidIncomeAfterTax = (annualIncomeBeforeTax > 0 && monthlyIncomeAfterTax > (annualIncomeBeforeTax / 12)) || (annualIncomeBeforeTax > 0 && monthlyIncomeAfterTax === 0);
                vm.notifications[applicant].invalidIncomeNonZero = annualIncomeBeforeTax === 0 && monthlyIncomeAfterTax > 0;
            }

            checkValues('primary');

            if (vm.notifications.isJointApplication) {
                checkValues('secondary');
            }
        }

        /**
         * @private
         * @param {Object} data Updated model data
         */
        function calculateIncome(data) {
            if (!data.primary || angular.isUndefined(data.interactions.primary.incomeAfterTax)) {
                return;
            }
            vm.userData.interactions.primary.monthlyIncome = vm.notifications.totalIncome = intervalAmountService.calculateMonthlyValue(data.interactions.primary.incomeAfterTax, data.interactions.primary.incomeAfterTaxInterval);

            if (vm.notifications.isJointApplication && angular.isDefined(data.interactions.secondary.incomeAfterTax)) {
                vm.userData.interactions.secondary.monthlyIncome = intervalAmountService.calculateMonthlyValue(data.interactions.secondary.incomeAfterTax, data.interactions.secondary.incomeAfterTaxInterval);

                vm.notifications.totalIncome += vm.userData.interactions.secondary.monthlyIncome;
            }

            var current = vm.userData.overdraft;

            if (vm.notifications.totalIncome === 0) {
                vm.userData.overdraft = false;
            } else {
                vm.userData.overdraft = current;
            }
        }

        function isProspectRetired(prospect) {
            return dataModelService.getUserDetails(prospect).employmentStatus === 'Retired';
        }

        /**
         * @private
         * @param {String} prospect Prospect (primary|secondary)
         * @returns {Boolean} Is the prospect required to input their retirement age
         */
        function showRetirementAgeForProspect(prospect) {
            return dataModelService.getApplicantAge(prospect) >= defaults.HIDE_RETIRMENT_AGE && !isProspectRetired(prospect);
        }

        /**
         * @private
         * @returns {Boolean} Are both prospects over 18 and not retired
         */
        function allProspectsEligible() {

            function isOver18(age) {
                return age >= 18;
            }

            var primaryAge = dataModelService.getApplicantAge('primary');
            if (dataModelService.isJointApplication()) {
                return isOver18(primaryAge) && isOver18(dataModelService.getApplicantAge('secondary'));
            } else {
                return isOver18(primaryAge);
            }
        }

        // IIFE init
        (function() {

            analyticsService.startFormTracking();

            function incomeDataHandler(newVal) {
                calculateIncome(newVal);
                checkIncomeValues(newVal);
            }

            var incomeDataWatcher = $rootScope.$watch(angular.bind(vm, function() {
                return vm.userData;
            }), incomeDataHandler, true);

            $rootScope.$on('$destroy', function() {
                incomeDataWatcher();
            });

        })();
    }

    angular
        .module('ntb-fe')
        .controller('IncomeController', IncomeController);

})(window.angular);