(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:overdraftService
     * @requires ntb-fe.service:dataModelService
     * @requires ntb-fe.service:intervalAmountService
     * @requires ntb-fe.constant:defaults
     *
     * @name ntb-fe.controller:OverdraftController
     * @description
     * Contact details controller
     *
     * @example
     * *OverdraftController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'OverdraftController as contact'
     * </pre>
     *
     * @ngInject
     */
    function OverdraftController($log, $rootScope, $state, $timeout, overdraftService, dataModelService, intervalAmountService, analyticsService, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:OverdraftController
         * @name ntb-fe.controller:OverdraftController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:OverdraftController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError : false,
            serverError  : false,
            isSubmitting : false,
            isJointApplication: dataModelService.isJointApplication(),
            monthlyIncome: parseInt(dataModelService.getUserDetails('primary').monthlyIncome, 10) + (dataModelService.isJointApplication() ? parseInt(dataModelService.getUserDetails('secondary').monthlyIncome, 10) : 0),
            maxOverdraft: 5000,
            overdraftIncrement : 20,
            primary : {
                firstName : dataModelService.getFirstName('primary')
            },
            secondary : {
                firstName : dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : ''
            }

        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:OverdraftController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('overdraft');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('overdraft');

        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         *
         */
        vm.skipOverdraft = function() {
            var tmpData = dataModelService.getBlankModelData('overdraft');
            vm.userData = tmpData;
            vm.userData.interactions = tmpData.interactions;
            vm.userData.overdraftAmount = 0;

            // Submit the userData to ensure B/E is up-to-date
            $rootScope.$applyAsync(function() {
                vm.submitUserData({ $valid: true });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:OverdraftController
         * @name vm.updateAmount
         * @description
         * Update the amount +- supplied increment
         *
         * @param {Integer} increment by which the amount should be increased (negative value to decrease)
         *
         * @type {Function}
         */
        vm.updateAmount = function(increment) {
            var oldAmount = parseInt(vm.userData.overdraftAmount, 10),
                newAmount = oldAmount + increment;

            if (newAmount >= defaults.OVERDRAFT_AMOUNT_MIN && newAmount <= vm.notifications.maxOverdraft) {
                vm.userData.overdraftAmount = newAmount;
            }
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:OverdraftController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="employmentForm" data-ng-submit="employment.submitUserData(employmentForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {

            vm.notifications.hasFormError = !form.$valid;

            if (vm.notifications.hasFormError || vm.notifications.invalidOutgoings) {

                if (vm.notifications.hasFormError) {
                    analyticsService.trackFormErrors(form);
                }

                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // Overdraft - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;

            dataModelService.updateModel('overdraft', vm.userData);
            analyticsService.addRoute('bankDetails');
            analyticsService.finishFormTracking();

            overdraftService.submitOverdraftDetails(vm.userData)
                .then(function() {
                    $state.go('bankDetails');
                }, function(error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitOverdraftDetails: ' + error.status);
                });
        };

        /**
         *
         * @returns {*}
         */
        vm.getTotalOutgoings = function() {

            function getTotalOutgoingsForProspect(prospect) {
                var data = vm.userData[prospect];
                return data.mortgageOrRent + data.councilTax + data.utilityBills + data.telecoms + data.mobilePhone + data.other;
            }
            calculateOutgoings(vm.userData);

            var outgoings = getTotalOutgoingsForProspect('primary') || 0;

            if (vm.notifications.isJointApplication) {
                outgoings += getTotalOutgoingsForProspect('secondary') || 0;
            }

            return outgoings;
        };

        /**
         *
         */
        vm.checkOutgoings = function() {

            var totalMonthlyOutgoings = vm.getTotalOutgoings(),
                totalMonthlyIncome = dataModelService.getUserDetails('primary').monthlyIncome || 0;

            if (vm.notifications.isJointApplication) {
                totalMonthlyIncome += dataModelService.getUserDetails('secondary').monthlyIncome || 0;
            }

            $log.info('vm.checkOutgoings:', totalMonthlyOutgoings, 'income:', totalMonthlyIncome);

            vm.notifications.invalidOutgoings = totalMonthlyOutgoings > totalMonthlyIncome;
        };

        /**
         *
         */
        vm.checkUserInput = function() {
            vm.userData.overdraftAmount = Math.max(100, Math.min(5000, Math.ceil(vm.userData.overdraftAmount / 20) * 20));
        };

        /**
         * @private
         * @param {Object} data Updated model data
         */
        function calculateOutgoings(data) {
            function updateUserDataWithProspectsOutgoings(prospect) {
                vm.userData[prospect].mortgageOrRent = intervalAmountService.calculateMonthlyValue(data.interactions[prospect].mortgageOrRent, data.interactions[prospect].mortgageOrRentInterval);
                vm.userData[prospect].councilTax     = intervalAmountService.calculateMonthlyValue(data.interactions[prospect].councilTax,     data.interactions[prospect].councilTaxInterval);
                vm.userData[prospect].utilityBills   = intervalAmountService.calculateMonthlyValue(data.interactions[prospect].utilityBills,   data.interactions[prospect].utilityBillsInterval);
                vm.userData[prospect].telecoms       = intervalAmountService.calculateMonthlyValue(data.interactions[prospect].tvAndBroadband, data.interactions[prospect].tvAndBroadbandInterval);
                vm.userData[prospect].mobilePhone    = intervalAmountService.calculateMonthlyValue(data.interactions[prospect].mobilePhone,    data.interactions[prospect].mobilePhoneInterval);
                vm.userData[prospect].other          = intervalAmountService.calculateMonthlyValue(data.interactions[prospect].livingCosts,    data.interactions[prospect].livingCostsInterval);
            }

            // $log.debug('Data:', data);

            updateUserDataWithProspectsOutgoings('primary');
            if (vm.notifications.isJointApplication) {
                updateUserDataWithProspectsOutgoings('secondary');
            }
        }

        // IIFE init
        (function() {

            $log.debug('Overdraft userData:', vm.notifications);

            analyticsService.startFormTracking();

            var currentOverdraftAmount = Math.max(100, parseInt(vm.userData.overdraftAmount, 10));
            
            vm.userData.overdraftAmount += 1;
            $timeout(function() {
                vm.userData.overdraftAmount = currentOverdraftAmount;
            }, 50);

            function outgoingsDataHandler(newVal) {
                calculateOutgoings(newVal);
            }

            var outgoingsDataWatcher = $rootScope.$watch(angular.bind(vm, function() {
                return vm.userData;
            }), outgoingsDataHandler, true);

            // Init check
            vm.checkOutgoings();

            $rootScope.$on('$destroy', function() {
                outgoingsDataWatcher();
            });

        })();
    }

    angular
        .module('ntb-fe')
        .controller('OverdraftController', OverdraftController);

})(window.angular);