(function(angular) {

    'use strict';

    /**
     *
     * @param momentService
     * @returns {Function}
     *
     * @ngInject
     */
    function switchingDates(momentService) {

        return function(date) {
            return momentService.getLongDate(date);
        };
    }

    angular
        .module('ntb-fe')
        .filter('switchingDates', switchingDates);

})(window.angular);