(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:switchingService
     * @description
     *
     * Switching service
     *
     * @requires ntb-fe.factory:switching
     *
     * @example
     *
     * <pre>
     *     function SomeController(switchingService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function switchingService($q, $filter, switching) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:switchingService
         * @name ntb-fe.service:switchingService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:switchingService
         * @name service.getSwitchingDates
         * @description
         * Get a list of valid switching dates based on switch type ('full' | 'partial')
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.getSwitchingDates = function(switchType) {
            return $q(function(resolve, reject) {
                switching.getSwitchingDates({ switchType: switchType }, function(response) {
                    resolve(response.switchingDates);
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:switchingService
         * @name service.validateAccount
         * @description
         * Validate the user account to ensure it is able to switch
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.validateAccount = function(userData) {
            return $q(function(resolve, reject) {
                switching.validateAccount($filter('switchingValidation')(userData), function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:switchingService
         * @name service.submitContactDetails
         * @description
         * Submit the userData Object via the contact factory and handle the response
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.submitSwitchingDetails = function(userData) {
            return $q(function(resolve, reject) {
                switching.save($filter('switchingDataFilter')(userData), function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('switchingService', switchingService);

})(window.angular);