(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:contactService
     * @description
     *
     * Contact details service
     *
     * @param {Object} $filter $filter
     * @requires ntb-fe.factory:contact
     *
     * @example
     *
     * <pre>
     *     function SomeController(contactService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function contactService($q, $filter, contact) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:contactService
         * @name ntb-fe.service:contactService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:contactService
         * @name service.submitContactDetails
         * @description
         * Submit the userData Object via the contact factory and handle the response
         *
         * Uses *$q* to return a promise which is resolved or rejected based on response from registration factory.
         *
         * @type {Object}
         */
        service.submitContactDetails = function(userData) {

            return $q(function(resolve, reject) {
                contact.save(userData, function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @private
         * @ngdoc method
         * @methodOf ntb-fe.service:contactService
         * @name service.postcodeLookup
         * @description
         * Get a list of premises based on postcode
         *
         * @param {String} postcode Postcode
         *
         * @type {Object}
         */
        service.postcodeLookup = function(postcode) {

            return $q(function(resolve, reject) {
                contact.lookupPostcode({ postcode: postcode }, function(response) {
                    resolve($filter('addressResultsFilter')(response));
                }, function(error) {
                    reject(error);
                });
            });
        };

        /**
         * @private
         * @ngdoc method
         * @methodOf ntb-fe.service:contactService
         * @name getAddress
         * @description
         * Get an address based on postcode and premises name
         *
         * @param {String} postcode Postcode
         * @param {String} premises Premises
         *
         * @type {Object}
         */
        service.getAddress = function(postcode, premises) {

            return $q(function(resolve, reject) {
                contact.fetchAddress({
                    postCode: postcode,
                    premisesName: premises
                }, function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('contactService', contactService);

})(window.angular);