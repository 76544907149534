(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:PreambleController
     * @description
     * Preamble controller
     *
     * @example
     * *PreambleController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'PreambleController as preamble'
     * </pre>
     *
     * @ngInject
     */
    function PreambleController($log, $window, $rootScope, $state, preambleService, dataModelService, analyticsService, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:PreambleController
         * @name ntb-fe.controller:PreambleController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:PreambleController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="preamble.notifications.serverError">
         *         <p>Some Error message</p>
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            serverError: false,
            hasFormError: false,
            isSubmitting: false,
            msn: void 0,
            validResidence: void 0
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:PreambleController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('registration');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('registration');

        /**
         *
         * @param item
         */
        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         *
         */
        vm.cashMinderLink = function() {
            $window.open('http://www.co-operativebank.co.uk/currentaccounts/cashminder');
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:PreambleController
         * @name vm.chooseApplicationType
         * @description
         * Handles the user selected application type
         *
         * @param {String} type Account type selection: *'sole' | 'joint'*
         *
         * @example
         * <pre>
         *     <button data-ng-click="preamble.chooseApplicationType('sole')">
         *         ...
         *     </button>
         * </pre>
         *
         * @type {Function}
         */
        vm.chooseApplicationType = function(type) {

            if (vm.userData.userChoices.accountType !== type) {
                vm.userData.userChoices.jointConsent = false;
                vm.userData.userChoices.acceptTerms = false;
                vm.userData.userChoices.fscs = false;
            }

            if (!vm.userData.userChoices.accountType) {
                window.animateScroll('preamble_anchor_1', 1000, 500);
            }

            vm.userData.userChoices.accountType = type;
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:PreambleController
         * @name vm.lookupEUPAD
         * @description
         *
         *
         * @param {String} applicant Current applicant
         *
         * @type {Function}
         */
        vm.lookupEUPAD = function(applicant) {

            var isFound = void 0;

            if (vm.userData.interactions[applicant].residence) {

                isFound = false;

                for (var i = 0, len = defaults.UEPAD_COUNTRIES.length; i < len; i ++) {
                    if (vm.userData.interactions[applicant].residence === defaults.UEPAD_COUNTRIES[i].code) {
                        isFound = true;
                    }
                }
            }

            vm.notifications.validResidence = isFound;
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:PreambleController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Form submitted
         *
         * @example
         * <pre>
         *     <form name="preambleForm" data-ng-submit="preamble.submitUserData(preambleForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {

            vm.notifications.serverError = false;
            vm.notifications.hasFormError = !form.$valid;

            if (!form.$valid) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }

            if (vm.notifications.isSubmitting) {
                return;
            }

            // Preamble - CPD Ignore
            vm.notifications.isSubmitting = true;

            dataModelService.updateModel('registration', vm.userData);
            analyticsService.addRoute('registration');
            analyticsService.finishFormTracking();

            preambleService.initServerComms().then(function() {
                $state.go('registration');
            }, function(error) {
                $log.error(error);
                vm.notifications.serverError = true;
                analyticsService.trackServerError('initServerComms: ' + error.status);
            });
        };

        (function() {
            analyticsService.startFormTracking();

            if (angular.isUndefined(vm.userData.msn)) {
                vm.userData.msn = preambleService.getMSN();
            }

            if (vm.userData.msn) {
                vm.notifications.msn = defaults.MSN_BASE_PATH + vm.userData.msn + defaults.MSN_IMG_EXT;
            }

            if (vm.userData.interactions && vm.userData.interactions.primary.residence) {
                vm.lookupEUPAD('primary');
            }

            if (vm.userData.interactions && vm.userData.interactions.secondary && vm.userData.interactions.secondary.residence) {
                vm.lookupEUPAD('secondary');
            }
        })();
    }

    angular
        .module('ntb-fe')
        .controller('PreambleController', PreambleController);

})(window.angular);