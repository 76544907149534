(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:contact
     * @description
     * **Factory**
     *
     * User contact factory
     *
     * @requires ntb-fe.constant:defaults
     * @return {Object} ngResource instance
     *
     * @example
     *
     * <pre>
     *     contact.save({}, function(response) {
     *         ...
     *     }, function(error) {
     *         ...
     *     });
     * </pre>
     *
     * @ngInject
     */
    function contact($resource, defaults) {
        return $resource('unused', null, {
            save : {
                method: 'POST',
                url : defaults.API_BASE_PATH + 'secure/contact-details'
            },
            lookupPostcode: {
                method: 'GET',
                url: defaults.API_BASE_PATH + 'premises/:postcode'
            },
            fetchAddress: {
                method: 'POST',
                url: defaults.API_BASE_PATH + 'address'
            }
        });
    }

    angular
        .module('ntb-fe')
        .factory('contact', contact);

})(window.angular);