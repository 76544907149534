(function (angular, window) {

    'use strict';

    /**
     * @ngdoc directive
     * @name ntb-fe.component:timeoutNotification
     * @description
     * **Component**
     * Timeout notification directive controller
     *
     * @restrict E
     * @requires ntb-fe.constant:defaults
     * @requires ntb-fe.service:timeoutService
     *
     * @ngInject
     */
    function controller($log, $rootScope, $state, $interval, defaults, timeoutService, analyticsService) {

        /* jshint validthis: true */
        var vm = this,
            countdownInterval;
        /* jshint validthis: false */

        /**
         * @ngdoc property
         * @name vm.notifications
         * @propertyOf ntb-fe.component:timeoutNotification
         * @description
         * Main controller notifications available to $scope.
         *
         * @type {Object}
         */
        vm.notifications = {
            expirationTime: defaults.APPLICATION_TIMEOUT,
            expirationBuffer: defaults.APPLICATION_TIMEOUT_BUFFER,
            timeRemaining: zero(defaults.APPLICATION_TIMEOUT_BUFFER) + ':00',
            displayModal: false,
            applicationExpired: false,
            timeoutProgress: {width: '100%'},
            resettingTimer: false
        };

        /**
         * @ngdoc method
         * @name vm.resetTimer
         * @propertyOf ntb-fe.component:timeoutNotification
         * @description
         * Reset expiration timers
         */
        vm.resetTimer = function () {

            vm.notifications.resettingTimer = true;

            timeoutService.resetTimer().then(function () {
                clearInterval();
                vm.notifications.resettingTimer = false;
                vm.notifications.displayModal = false;
            }, function (error) {
                $log.error('ERROR RESETTING TIMER', error);
                if (error.status === 401) {
                    $log.debug('401 returned; application expired', error);
                    vm.notifications.resettingTimer = false;
                    vm.notifications.applicationExpired = true;
                }
            });
        };

        /**
         * @ngdoc method
         * @name vm.resumeApplication
         * @propertyOf ntb-fe.component:timeoutNotification
         * @description
         * End session and resume the application
         */
        vm.resumeApplication = function () {
            vm.notifications.displayModal = false;
        };

        /**
         * @private
         */
        function clearInterval() {
            $interval.cancel(countdownInterval);
        }

        /**
         * @private
         */
        function zero(num) {
            return num < 10 ? '0' + num : num.toString();
        }

        /**
         * @private
         */
        function startCountdown() {

            vm.notifications.expirationTime = timeoutService.serverExpiry;

            var time = defaults.APPLICATION_TIMEOUT_BUFFER * 60,
                minutes,
                seconds,
                focusObj = window['document'].getElementById('timeout_title');

            if (focusObj && focusObj.focus) {
                focusObj.focus();
            }

            countdownInterval = $interval(function () {
                time -= 1;
                minutes = Math.floor((time / 60) % 60);
                seconds = Math.floor(time % 60);
                vm.notifications.timeRemaining = zero(Math.max(0, minutes)) + ':' + zero(Math.max(0, seconds));
                vm.notifications.timeoutProgress.width = +Math.floor((time / (defaults.APPLICATION_TIMEOUT_BUFFER * 60)) * 100) + '%';
            }, 1000);
        }

        // IIFE init
        (function () {

            $log.debug('Timeout component listening...');

            var notifyWatcher = $rootScope.$on('applicationTimeoutNotifier', function (event, notification) {

                var isFatal = notification.type === 'fatal';

                vm.notifications.displayModal = true;
                vm.notifications.applicationExpired = isFatal;

                $log.debug('Timeout component notified:', notification.type);

                clearInterval();

                analyticsService.trackCustomEvent({
                    linkTrackVars: 'events',
                    linkTrackEvents: isFatal? 'event50' : 'event53',
                    events: isFatal? 'event50' : 'event53'
                }, isFatal? 'App Locked' : 'App Timeout');

                function switchState() {
                    $state.go('resume', {}, { reload: true });
                }

                if (notification.type !== 'fatal') {
                    startCountdown();
                } else {

                    var focusObj = window['document'].getElementById('locked_title');
                    if (focusObj && focusObj.focus) {
                        focusObj.focus();
                    }

                    timeoutService.endSession().then(switchState, switchState);
                }
            });

            vm.$onDestroy = function () {
                notifyWatcher();
            };

        })();
    }

    angular
        .module('ntb-fe')
        .component('timeoutNotification', {
            controller: controller,
            templateUrl: 'timeout-notification.template.html'
        });

})(window.angular, window);