(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.service:preambleService
     * @description
     *
     * Preamble service
     *
     * @example
     *
     * <pre>
     *     function SomeController(preambleService) {
     *
     *     }
     * </pre>
     *
     * @ngInject
     */
    function preambleService($q, $cookies, session, defaults) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.service:preambleService
         * @name ntb-fe.service:bankDetailsService.service
         * @description
         * Verbose *this* to expose as service
         *
         * @type {Object}
         */
        /* jshint validthis: true */
        var service = this;
        /* jshint validthis: false */

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:preambleService
         * @name service.getMSN
         * @description
         * Get the MSN from the cookie via ngCookies
         *
         * @return {String} MSN
         */
        service.getMSN = function () {
            return $cookies.get(defaults.MSN_COOKIE_PATH);

            /*
            return $q(function(resolve) {
                $http({
                    method: 'GET',
                    url: defaults.MSN_BASE_PATH + $cookies.get(defaults.MSN_COOKIE_PATH) + defaults.MSN_IMG_EXT
                }).then(function successCallback() {
                    resolve(defaults.MSN_BASE_PATH + $cookies.get(defaults.MSN_COOKIE_PATH) + defaults.MSN_IMG_EXT);
                }, function errorCallback() {
                    resolve('PATH_TO_LOCAL_BLANK_IMAGE'); // @TODO
                });
            });
            */
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.service:preambleService
         * @name service.initServerComms
         * @description
         * Initialise the server comms
         *
         * @return {String} MSN
         */
        service.initServerComms = function () {
            return $q(function(resolve, reject) {
                session.start(null, function(response) {
                    resolve(response);
                }, function(error) {
                    reject(error);
                });
            });
        };
    }

    angular
        .module('ntb-fe')
        .service('preambleService', preambleService);

})(window.angular);