(function (angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:unsubscribeService
     * @requires ntb-fe.service:analyticsService
     *
     * @name ntb-fe.controller:UnsubscribeController
     * @description
     * Unsubscribe controller
     *
     * @example
     * *BankDetailsController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'BankDetailsController as bankDetails'
     * </pre>
     *
     * @ngInject
     */
    function UnsubscribeController($state, unsubscribeService, analyticsService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:UnsubscribeController
         * @name ntb-fe.controller:UnsubscribeController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:UnsubscribeController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="unsubscribe.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            serverError: false,
            isWorking: true
        };

        // IIFE init
        (function () {

            // Immediately call the unsubscribe service with the UUID
            unsubscribeService.callUnsubscribe($state.params.uuid).then(function() {
                vm.notifications.isWorking = false;
            }, function(error) {
                vm.notifications.isWorking = false;
                vm.notifications.serverError = true;
                analyticsService.trackServerError('callUnsubscribe: ' + error.status);
            });

        })();
    }

    angular
        .module('ntb-fe')
        .controller('UnsubscribeController', UnsubscribeController);

})(window.angular);
