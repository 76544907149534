(function(angular) {

    'use strict';

    /**
     * @ngdoc service
     * @name ntb-fe.factory:resume
     * @description
     * **Factory**
     *
     * Bank details factory
     *
     * @requires ntb-fe.constant:defaults
     * @return {Object} ngResource instance
     *
     * @example
     *
     * <pre>
     *     rewards.authenticate({}, function(response) {
     *         ...
     *     }, function(error) {
     *         ...
     *     });
     * </pre>
     *
     * @ngInject
     */
    function resume($resource, defaults) {
        return $resource('unused', { uuid: '@uuid' }, {
            emailCheck: {
                method: 'POST',
                url: defaults.API_BASE_PATH + 'email/check'
            },
            request: {
                method: 'POST',
                url: defaults.API_BASE_PATH + 'resume/request'
            },
            check: {
                method: 'GET',
                url: defaults.API_BASE_PATH + 'resume/check/:uuid'
            },
            authenticate: {
                method: 'POST',
                url: defaults.API_BASE_PATH + 'resume/authenticate'
            }
        });
    }

    angular
        .module('ntb-fe')
        .factory('resume', resume);

})(window.angular);