(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc filter
     * @name ntb-fe.filter:switchingValidation
     * @description
     * Switching validation filter
     *
     * @return {Function} Filter method
     *
     * @example
     * <pre>
     *     $filter('switchingValidation')(data);
     * </pre>
     *
     * @ngInject
     */
    function switchingValidation() {

        return function(userData) {
            var copy = angular.copy(userData);
            if (angular.isDefined(copy.sortcode)) {
                copy.sortcode = copy.sortcode.replace(/-/g, '');
            }
            return copy;
        };

    }

    angular
        .module('ntb-fe')
        .filter('switchingValidation', switchingValidation);

})(window.angular);