(function(angular) {

    'use strict';

    /**
     *
     * @param incomeService
     * @returns {Function}
     *
     * @ngInject
     */
    function incomeData(intervalAmountService) {
        return function(data) {
            if (data && data.primary) {
                data.primary.incomeBeforeTax = intervalAmountService.calculateYearlyValue(data.interactions.primary.incomeBeforeTax, data.interactions.primary.incomeBeforeTaxInterval);
                data.primary.incomeAfterTax = intervalAmountService.calculateMonthlyValue(data.interactions.primary.incomeAfterTax, data.interactions.primary.incomeAfterTaxInterval);

                if (data.interactions.secondary && data.interactions.secondary.incomeBeforeTax) {
                    data.secondary.incomeBeforeTax = intervalAmountService.calculateYearlyValue(data.interactions.secondary.incomeBeforeTax, data.interactions.secondary.incomeBeforeTaxInterval);
                    data.secondary.incomeAfterTax = intervalAmountService.calculateMonthlyValue(data.interactions.secondary.incomeAfterTax, data.interactions.secondary.incomeAfterTaxInterval);
                }
            }
            return data;
        };
    }

    angular
        .module('ntb-fe')
        .filter('incomeData', incomeData);

})(window.angular);