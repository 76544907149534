(function(angular) {

    'use strict';

    /**
     * @constructor
     * @ngdoc controller
     *
     * @requires ntb-fe.service:rewardsService
     * @requires ntb-fe.service:dataModelService
     *
     * @name ntb-fe.controller:RewardsController
     * @description
     * Contact details controller
     *
     * @example
     * *RewardsController* should be used with the {@link http://toddmotto.com/digging-into-angulars-controller-as-syntax/ Controller as} syntax
     * <pre>
     *     controller: 'RewardsController as contact'
     * </pre>
     *
     * @ngInject
     */
    function RewardsController($state, $rootScope, rewardsService, dataModelService, analyticsService) {

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:RewardsController
         * @name ntb-fe.controller:RewardsController.vm
         * @description
         * Using *controller as* syntax
         * Verbose *this* to expose Controller to DOM
         *
         * @type {Object}
         */
        var vm = this;

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:RewardsController
         * @name vm.notifications
         * @description
         * Notifications to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     <div data-ng-if="contact.notifications.serverError">
         *         ...
         *     </div>
         * </pre>
         *
         * @type {Object}
         */
        vm.notifications = {
            hasFormError: false,
            serverError : false,
            isSubmitting: false,
            noUserChoice: false,
            isJointApplication: dataModelService.isJointApplication(),
            secondary : {
                firstName : dataModelService.isJointApplication() ? dataModelService.getFirstName('secondary') : ''
            }
        };

        /**
         * @ngdoc property
         * @propertyOf ntb-fe.controller:RewardsController
         * @name vm.userData
         * @description
         * User input data to be passed to and handled by the DOM
         *
         * @example
         * <pre>
         *     vm.userData = dataModelService.getModelData('rewards');
         * </pre>
         *
         * @type {Object}
         */
        vm.userData = dataModelService.getModelData('rewards');

        vm.trackFormElement = function(item) {
            analyticsService.trackFormInteraction(item);
        };

        /**
         *
         */
        vm.resetForm = function(keepType, value) {
            vm.userData = angular.copy(dataModelService.getBlankModelData('rewards'));
            $rootScope.$applyAsync(function() {
                vm.userData.optIn = false;

                if (keepType) {
                    vm.notifications.noUserChoice = false;
                    vm.userData.optIn = true;
                    vm.userData.rewardsType = value;
                }
            });
        };

        /**
         * @ngdoc method
         * @methodOf ntb-fe.controller:RewardsController
         * @name vm.submitUserData
         * @description
         * Passes the user input data to the service and handles the response
         *
         * @param {Object} form Submitted form
         *
         * @example
         * <pre>
         *     <form name="contactForm" data-ng-submit="contact.submitUserData(contactForm.$valid)">
         *         ...
         *     </form>
         * </pre>
         *
         * @type {Function}
         */
        vm.submitUserData = function(form) {

            vm.notifications.hasFormError = !form.$valid || (!vm.userData.rewardsType  && vm.userData.optIn);

            vm.notifications.noUserChoice = !vm.userData.rewardsType && vm.userData.optIn;

            // Rewards
            if (vm.notifications.hasFormError) {
                analyticsService.trackFormErrors(form);
                $rootScope.$broadcast('hasFormError');
                return;
            }
            if (vm.notifications.isSubmitting) {
                return;
            }

            // Rewards - CPD Ignore
            vm.notifications.isSubmitting = true;
            vm.notifications.serverError = false;

            if (angular.isDefined(vm.userData.rewardsType)) {
                analyticsService.trackEvent('NTB - Rewards ' + vm.userData.rewardsType, 'NTB - Rewards');
            }

            dataModelService.updateModel('rewards', vm.userData);
            analyticsService.addRoute('bank-details');
            analyticsService.finishFormTracking();

            rewardsService.submitRewardsOptions(vm.userData)
                .then(function() {
                    $state.go('bankDetails');
                }, function(error) {
                    vm.notifications.isSubmitting = false;
                    vm.notifications.serverError = true;
                    analyticsService.trackServerError('submitRewardsOptions: ' + error.status);
                });
        };

        (function() {
            analyticsService.startFormTracking();
        })();
    }

    angular
        .module('ntb-fe')
        .controller('RewardsController', RewardsController);

})(window.angular);
